import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_UPLOAD_${type?.toUpperCase()}`,
  (type) => `SET_UPLOAD_${type?.toUpperCase()}`,
  (type) => `POST_UPLOAD_${type?.toUpperCase()}`,
  (type) => `PUT_UPLOAD_${type?.toUpperCase()}`,
  (type) => `DELETE_UPLOAD_${type?.toUpperCase()}`
);

const question = properties('question');
const questionList = properties('questionList');
const questionDetailList = properties('questionDetailList');
const questionApprove = properties('questionApprove');
const lastQuestion = properties('lastQuestion');
const questionFilter = properties('questionFilter');
const searchFilter = properties('searchFilter');
const questionCount = properties('questionCount');
const questionReturn = properties('questionReturn');
const questionImage = properties('questionImage');
const answerImage = properties('answerImage');
const selectedTab = properties('selectedTab');
const translateQuestion = properties('translateQuestion');

export {
  question,
  questionList,
  questionDetailList,
  questionApprove,
  lastQuestion,
  questionFilter,
  searchFilter,
  questionCount,
  questionReturn,
  questionImage,
  answerImage,
  selectedTab,
  translateQuestion
};
