import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profileInfo } from 'views/profile/redux/actions';
import { getUserInfo } from 'views/profile/redux/selectors';

export default function useStaffValidation() {
  const dispatch = useDispatch();
  const staffId = useSelector((state) => state?.auth?.data?.StaffId);
  const userType = useSelector((state) => state?.auth?.data?.UserType);
  const user = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(profileInfo.get());
  }, []);

  let isStaffValid = true;
  if (userType === 'Staff') {
    if (!staffId || Number(staffId) < 1) {
      isStaffValid = false;
      return [isStaffValid];
    }
  }
  if (userType === 'Staff' && !user?.isEmailVerified) {
    isStaffValid = user?.isEmailVerified;
    return [isStaffValid];
  }
  if (userType === 'Staff' && !user?.isMobileVerified) {
    isStaffValid = user?.isMobileVerified;
    return [isStaffValid];
  }
  return [isStaffValid];
}
