import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const PREFIX = 'GridContainer';

const classes = {
  grid: `${PREFIX}-grid`
};

const StyledGrid = styled(Grid)({
  [`& .${classes.grid}`]: {
    marginRight: '-25px',
    marginLeft: '-25px',
    width: 'auto'
  }
});
function GridContainer(props) {
  const { children, className, ...rest } = props;
  return (
    <StyledGrid container {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </StyledGrid>
  );
}

export default memo(GridContainer);
