import Dashboard from 'assets/img/module/dashboard.png';
import Exam from 'assets/img/module/exam.png';
import History from 'assets/img/module/history.png';
import Events from 'assets/img/module/event.png';
import Subscription from 'assets/img/module/subscribe.png';

const studentRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard
  },
  {
    path: '/examHome',
    name: 'Create Exam',
    icon: Exam
  },
  {
    path: '/history',
    name: 'History',
    icon: History
  },
  {
    path: '/events',
    name: 'Events',
    icon: Events
  },
  {
    path: '/subscription',
    name: 'Subscription',
    icon: Subscription
  }
];

export default studentRoutes;
