import { callApi, callFormApi } from '@helpers/utilities';

const callerFormAuth = (method, url, body) => callFormApi({ method, url, body }).withAuth();

const getClientApi = () =>
  callApi({
    method: 'GET',
    url: `/clients?QueryType=3`
  }).withAuth();

const postStudentBulkImportApi = (uploadRequest) =>
  callerFormAuth('POST', '/student/register/upload', uploadRequest.formParams);

const postStudentDownloadExcelApi = (userJson) =>
  callApi({
    method: 'POST',
    url: `/student/register/excel`,
    body: [...userJson],
    responseType: 'blob'
  }).withAuth();

const postStaffBulkImportApi = (uploadRequest) =>
  callerFormAuth('POST', '/staff/register/upload', uploadRequest.formParams);

const postStaffDownloadExcelApi = (userJson) =>
  callApi({
    method: 'POST',
    url: `/staff/register/excel`,
    body: [...userJson],
    responseType: 'blob'
  }).withAuth();

export {
  postStudentBulkImportApi,
  postStudentDownloadExcelApi,
  getClientApi,
  postStaffBulkImportApi,
  postStaffDownloadExcelApi
};
