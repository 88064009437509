import { lazy } from 'react';

export const CreateTestConfig = {
  routes: [
    {
      path: '/createTest',
      exact: true,
      component: lazy(() => import('./sections/commonTestList'))
    },
    {
      path: '/createTest/new',
      exact: true,
      component: lazy(() => import('./sections/testCreationForm'))
    },
    {
      path: '/createTest/edit',
      exact: true,
      component: lazy(() => import('./sections/commonTestEdit'))
    },
    {
      path: '/createTest/searchQuestions',
      exact: true,
      component: lazy(() => import('./sections/createTest'))
    },
    {
      path: '/createTest/questionReview',
      exact: true,
      component: lazy(() => import('./sections/questionReview'))
    },
    {
      path: '/createTest/questionEdit',
      exact: true,
      component: lazy(() => import('./sections/questionListEdit'))
    },
    {
      path: '/createTest/recreate',
      exact: true,
      component: lazy(() => import('./sections/commonTestRecreate'))
    },
    {
      path: '/createTest/review',
      exact: true,
      component: lazy(() => import('./sections/commonTestReview'))
    }
  ]
};
