import { lazy } from 'react';

export const UserConfig = {
  routes: [
    {
      name: 'login',
      path: '/login',
      exact: true,
      component: lazy(() => import('./login'))
    },
    {
      path: '/signUp',
      exact: true,
      component: lazy(() => import('./signUp'))
    },
    {
      path: '/signUpComplete',
      exact: true,
      component: lazy(() => import('./signUp/confirmSignup'))
    },
    {
      path: '/forgetPassword',
      exact: true,
      component: lazy(() => import('./forgetPassword'))
    },
    {
      path: '/fetchOtp',
      exact: true,
      component: lazy(() => import('./component/fetchOtp'))
    }
  ]
};
