import { push } from 'connected-react-router';
import * as CommonActions from 'app/redux/actions';
import { executeEpic } from '@helpers/utilities';
import * as Types from './types';
import {
  testInfo,
  testInfoCount,
  analysisInfo,
  testDetail,
  studentInfo,
  studentCourse
} from './actions';
import {
  getTestInfoApi,
  getTestAnalysisApi,
  getTestDetailApi,
  getStudentInfoApi,
  getStudentCourseApi
} from '../api';

const getTestInfoEpic = (action$) =>
  executeEpic(Types.GET_TESTINFO_HISTORY, getTestInfoApi, (responseData) => [
    testInfo.set(responseData.response),
    testInfoCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10)),
    CommonActions.stopLoading()
  ])(action$);

const getTestAnalysisInfoEpic = (action$) =>
  executeEpic(Types.GET_ANALYSISINFO_HISTORY, getTestAnalysisApi, (responseData) => [
    analysisInfo.set(responseData.response),
    CommonActions.stopLoading(),
    push('/analysis')
  ])(action$);

const getTestDetailEpic = (action$) =>
  executeEpic(Types.GET_TESTDETAIL_HISTORY, getTestDetailApi, (responseData) => [
    testDetail.set(responseData.response),
    CommonActions.stopLoading(),
    push('/analysis/examReview')
  ])(action$);

const getStudentInfoEpic = (action$) =>
  executeEpic(Types.GET_STUDENTINFO_HISTORY, getStudentInfoApi, (responseData) => [
    studentInfo.set(responseData.response),
    testInfoCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10)),
    CommonActions.stopLoading()
  ])(action$);

const getStudentCourseEpic = (action$) =>
  executeEpic(Types.GET_STUDENTCOURSE_HISTORY, getStudentCourseApi, (responseData) => [
    studentCourse.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

export default [
  getTestInfoEpic,
  getTestAnalysisInfoEpic,
  getTestDetailEpic,
  getStudentInfoEpic,
  getStudentCourseEpic
];
