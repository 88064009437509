import { memo, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CustomCheckBox(props) {
  const [checked, setChecked] = useState(props.checked ?? false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.handleChecked(props.id, event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox checked={checked} onChange={handleChange} name={props.name} color="primary" />
      }
      label={props.title}
    />
  );
}

export default memo(CustomCheckBox);
