import produce from 'immer';
import * as Types from './types';

const DEFAULT_STATE = {
  navigationFilters: {
    page: 1,
    pageSize: 20,
    sorts: '-updatedAt',
    filters: ''
  },
  filter: {
    clientId: -1,
    examId: -1,
    courseId: -1,
    testType: -1,
    questionLevel: -1,
    status: -1,
    firstName: '',
    lastName: '',
    subjectId: -1,
    testReferenceId: -1
  },
  count: 1,
  selectedQuestion: 0
};

const history = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_TESTINFO_HISTORY:
      state.testHistory = action.payload;
      return state;
    case Types.SET_TESTINFOCOUNT_HISTORY:
      state.count = action.payload;
      return state;
    case Types.SET_NAVIGATIONFILTER_HISTORY:
      state.navigationFilters = { ...state.navigationFilters, ...action.payload };
      return state;
    case Types.SET_ANALYSISINFO_HISTORY: {
      const responseData = action.payload;
      let detailAnalysisData = { Subject: [], Unit: [], Level: [], Type: [], Topic: [] };
      Object.keys(responseData ?? []).map((analysisKey) => {
        switch (analysisKey) {
          case 'levelAnalysis':
            detailAnalysisData = {
              ...detailAnalysisData,
              Level: responseData[analysisKey]
            };
            break;
          case 'subjectAnalysis':
            detailAnalysisData = {
              ...detailAnalysisData,
              Subject: responseData[analysisKey]
            };
            break;
          case 'topicAnalysis':
            detailAnalysisData = {
              ...detailAnalysisData,
              Topic: responseData[analysisKey]
            };
            break;
          case 'typeAnalysis':
            detailAnalysisData = {
              ...detailAnalysisData,
              Type: responseData[analysisKey]
            };
            break;
          case 'unitAnalysis':
            detailAnalysisData = {
              ...detailAnalysisData,
              Unit: responseData[analysisKey]
            };
            break;
          default:
            detailAnalysisData = {
              ...detailAnalysisData
            };
        }
      });
      state.analysis = detailAnalysisData;
      return state;
    }
    case Types.SET_TESTDETAIL_HISTORY: {
      const { test, testQus } = action.payload;
      // const orderItem = ['Physics', 'Chemistry', 'Botany', 'Zoology', 'Mathematics'];
      // const questionList = testQus
      //   .sort((a, b) => orderItem.indexOf(a.subjectName) - orderItem.indexOf(b.subjectName))
      //   .map((question, index) => ({ ...question, questionKey: index }));
      const questionList = testQus
        .sort((a, b) => a.orderNo - b.orderNo)
        .map((question, index) => ({ ...question, questionKey: index }));
      state.questions = { test, testQus: questionList };
      return state;
    }
    case Types.SET_CURRENTQUESTION_HISTORY:
      state.selectedQuestion = action.payload;
      return state;
    case Types.SET_FILTER_HISTORY:
      state.filter = action.payload;
      return state;
    case Types.SET_STUDENTINFO_HISTORY:
      state.studentList = action.payload;
      return state;
    case Types.SET_STUDENTCOURSE_HISTORY:
      state.studentCourse = action.payload;
      return state;
    case Types.SET_INDIVIDUALFILTER_HISTORY:
      state.individualFilter = action.payload;
      return state;
    case Types.SET_NAMEFILTER_HISTORY:
      state.nameFilter = action.payload;
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { history };
