import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'views/common/redux/actions';
import { course } from 'views/CreateTest/redux/actions';
import CustomSelect from '../CustomSelect';

const defaultOption = { label: 'None', value: -1 };
const MASTER_TYPE = {
  standard: 'standard',
  department: 'department',
  board: 'board',
  medium: 'medium',
  state: 'state',
  district: 'district',
  zipCode: 'zipcode',
  exams: 'exams',
  testType: 'testtype',
  subject: 'subject',
  course: 'course',
  unit: 'unit',
  topic: 'topic',
  level: 'level',
  testStatus: 'teststatus',
  schoolType: 'schooltype',
  examList: 'examlist',
  courseList: 'courselist',
  clientList: 'clientlist',
  commonTestList: 'commontestlist',
  subjectList: 'subjectlist',
  personList: 'personlist'
};

const callApi = (dispatch) => (type, getBy, getByExam) => {
  getBy = getBy || '';
  switch (type.toLowerCase()) {
    case MASTER_TYPE.standard:
      dispatch(Actions.standard.get(getBy));
      break;
    case MASTER_TYPE.department:
      dispatch(Actions.department.get(getBy));
      break;
    case MASTER_TYPE.board:
      dispatch(Actions.board.get(getBy));
      break;
    case MASTER_TYPE.medium:
      dispatch(Actions.medium.get(getBy));
      break;
    case MASTER_TYPE.state:
      dispatch(Actions.state.get(getBy));
      break;
    case MASTER_TYPE.district:
      dispatch(Actions.district.get(getBy));
      break;
    case MASTER_TYPE.zipCode:
      dispatch(Actions.zipCode.get(getBy));
      break;
    case MASTER_TYPE.exams:
      dispatch(Actions.exams.get(getBy));
      break;
    case MASTER_TYPE.testType:
      dispatch(Actions.testType.get(getBy));
      break;
    case MASTER_TYPE.subject:
      if (getBy) dispatch(Actions.subject.get({ getBy, getByExam }));
      else dispatch(Actions.subject.get({ getByExam }));
      break;
    case MASTER_TYPE.course:
      dispatch(course.get(getBy));
      break;
    case MASTER_TYPE.unit:
      dispatch(Actions.unit.get(getBy));
      break;
    case MASTER_TYPE.topic:
      dispatch(Actions.topic.get(getBy));
      break;
    case MASTER_TYPE.level:
      dispatch(Actions.level.get(getBy));
      break;
    case MASTER_TYPE.testStatus:
      dispatch(Actions.testStatus.get(getBy));
      break;
    case MASTER_TYPE.schoolType:
      dispatch(Actions.schoolType.get(getBy));
      break;
    case MASTER_TYPE.examList:
      dispatch(Actions.examList.get(getBy));
      break;
    case MASTER_TYPE.courseList:
      dispatch(Actions.courseList.get(getBy));
      break;
    case MASTER_TYPE.clientList:
      dispatch(Actions.clientList.get(getBy));
      break;
    case MASTER_TYPE.commonTestList:
      dispatch(Actions.commonTestList.get(getBy));
      break;
    case MASTER_TYPE.subjectList:
      dispatch(Actions.subjectList.get(getBy));
      break;
    case MASTER_TYPE.personList:
      dispatch(Actions.personList.get(getBy));
      break;
    default:
      console.warn(
        `["${type}" is no mapped] Dispatch respective API in "Components/MasterSelection"`
      );
      break;
  }
};

const optionMapper = (label, collection) => {
  const _defaultOption = { ...defaultOption, label };
  const defaultCollection = [_defaultOption];

  return (labelKey, valueKey) => {
    if (!collection) {
      return defaultCollection;
    }

    const mappedCollection = collection?.map((each) => ({
      label: each[labelKey],
      value: each[valueKey],
      ...each
    }));

    return [_defaultOption, ...mappedCollection];
  };
};

const mapSource = (type, pHolder, master) => {
  switch (type.toLowerCase()) {
    case MASTER_TYPE.standard:
      return optionMapper(pHolder, master?.standards)('name', 'id');
    case MASTER_TYPE.department:
      return optionMapper(pHolder, master?.departments)('name', 'id');
    case MASTER_TYPE.board:
      return optionMapper(pHolder, master?.boards)('description', 'board');
    case MASTER_TYPE.medium:
      return optionMapper(pHolder, master?.medium)('name', 'id');
    case MASTER_TYPE.state:
      return optionMapper(pHolder, master?.states)('stateName', 'id');
    case MASTER_TYPE.district:
      return optionMapper(pHolder, master?.districts)('districtName', 'id');
    case MASTER_TYPE.zipCode:
      return optionMapper(pHolder, master?.zipCodes)('pincode', 'pincode');
    case MASTER_TYPE.exams:
      return optionMapper(pHolder, master?.exams)('name', 'id');
    case MASTER_TYPE.testType:
      return optionMapper(pHolder, master?.testTypes)('name', 'testType');
    case MASTER_TYPE.subject:
      return optionMapper(pHolder, master?.subjects)('subjectName', 'subjectId');
    case MASTER_TYPE.course:
      return optionMapper(pHolder, master?.courses)('name', 'id');
    case MASTER_TYPE.unit:
      return optionMapper(pHolder, master?.units)('name', 'id');
    case MASTER_TYPE.topic:
      return optionMapper(pHolder, master?.topics)('name', 'id');
    case MASTER_TYPE.level:
      return optionMapper(pHolder, master?.levels)('name', 'questionLevel');
    case MASTER_TYPE.testStatus:
      return optionMapper(pHolder, master?.testStatuses)('name', 'status');
    case MASTER_TYPE.schoolType:
      return optionMapper(pHolder, master?.schoolTypes)('name', 'id');
    case MASTER_TYPE.examList:
      return optionMapper(pHolder, master?.examList)('name', 'id');
    case MASTER_TYPE.courseList:
      return optionMapper(pHolder, master?.courseList)('name', 'id');
    case MASTER_TYPE.clientList:
      return optionMapper(pHolder, master?.clientList)('name', 'id');
    case MASTER_TYPE.commonTestList:
      return optionMapper(pHolder, master?.commonTestList)('name', 'id');
    case MASTER_TYPE.subjectList:
      return optionMapper(pHolder, master?.subjectList)('name', 'id');
    case MASTER_TYPE.personList:
      return optionMapper(pHolder, master?.personList)('name', 'id');
    default:
      return optionMapper();
  }
};

const mapCourseSource = (type, pHolder, createTest) => {
  switch (type.toLowerCase()) {
    case MASTER_TYPE.course:
      return optionMapper(pHolder, createTest?.courses)('name', 'id');
    default:
      return optionMapper();
  }
};

function MasterSelection(props) {
  const { label, placeholder, value, type, getBy, getByExam, onChange, multiple, ...other } = props;
  const [selectedValue, setSelectedValue] = useState(!multiple ? -1 : []);
  const [source, setSource] = useState([defaultOption]);
  const dispatch = useDispatch();

  const master = useSelector((state) => state?.master);
  const createTest = useSelector((state) => state?.createTest);

  useEffect(() => setSelectedValue(value), [value]);
  useEffect(() => {
    if (getBy !== null) callApi(dispatch)(type, getBy, getByExam, master);
  }, [getBy]);
  useEffect(() => setSource(mapSource(type, placeholder, master)), [master]);
  useEffect(() => setSource(mapCourseSource(type, placeholder, createTest)), [createTest]);

  const handleDropdownChange = (e) => {
    const { value } = e?.target;
    setSelectedValue(value);
    if (onChange) onChange(e, !multiple ? source?.find((each) => each.value === value) : value);
  };

  return (
    <CustomSelect
      {...{
        label,
        variant: other.variant || 'outlined',
        placeholder,
        selectedValue,
        dropdownValues: !multiple ? source : source.filter((each) => each.value !== -1),
        fullWidth: true,
        onDropdownChange: handleDropdownChange,
        multiple,
        ...other
      }}
    />
  );
}

MasterSelection.prototype = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired || PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getBy: PropTypes.string
};

export default memo(MasterSelection);
export { MASTER_TYPE };
