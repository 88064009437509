import { createSelector } from 'reselect';

const getState = (state) => state.showLoading;
const theme = (state) => state.appTheme;
const userState = (state) => state.userData;
const getRouter = (state) => state.router;

const getLoading = createSelector(getState, (getState) => getState?.loading);
const getThemeType = createSelector(theme, (theme) => theme?.isDarkTheme);
const getDefaultTheme = createSelector(theme, (theme) => theme?.defaultLight);
const getCustomTheme = createSelector(theme, (theme) => theme?.customTheme);
const getDarkTheme = createSelector(theme, (theme) => theme?.defaultDark);
const getUserType = createSelector(userState, (userState) => userState?.user?.userType);
const getPath = createSelector(getRouter, (getRouter) => getRouter?.location?.pathname);

export {
  getLoading,
  getThemeType,
  getDefaultTheme,
  getCustomTheme,
  getDarkTheme,
  getUserType,
  getPath
};
