import { forwardRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as Actions from 'app/redux/actions';

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

function CustomDialog(props) {
  const {
    id,
    open,
    dialogTitle,
    dialogContent,
    buttonPrimaryText,
    buttonSecondaryText,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    disableEscKey,
    disableBackDrop,
    component,
    maxWidth = 'md',
    disableAutoFocus,
    ...otherProps
  } = props;

  const dispatch = useDispatch();
  const dialogs = useSelector((state) => state?.naviraDialog?.dialogs);

  useEffect(() => {
    if (id && !dialogs?.find((dialog) => dialog === id)) {
      dispatch(Actions.showDialog(id));
    }
  }, [id]);

  const closeDialog = () => dispatch(Actions.hideDialog(id));

  const routeClicks = (handler, event, closer = Actions.hideDialog(id)) =>
    handler && handler(event, closer);

  const primaryBtnClick = (e) => routeClicks(handlePrimaryButtonClick, e);

  const secondaryBtnClick = (e) => routeClicks(handleSecondaryButtonClick, e);

  return (
    <Dialog
      maxWidth={maxWidth}
      open={dialogs?.includes(id) ?? false}
      TransitionComponent={Transition}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog(event, reason);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={disableEscKey}
      {...otherProps}
    >
      {dialogTitle ? <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle> : null}
      {dialogContent && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
        </DialogContent>
      )}
      {component && <DialogContent>{component}</DialogContent>}
      <DialogActions>
        {buttonSecondaryText ? (
          <Button onClick={secondaryBtnClick} color="primary">
            {buttonSecondaryText}
          </Button>
        ) : null}
        {buttonPrimaryText ? (
          <Button onClick={primaryBtnClick} color="primary" autoFocus={!disableAutoFocus}>
            {buttonPrimaryText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default memo(CustomDialog);
