import produce from 'immer';
import * as Types from 'views/common/redux/types';

const logoutInitialState = {};
const logout = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_FLAG_FOR_LOGOUT:
      state.logoutFlag = action.payload;
      return state;
    default:
      return state;
  }
}, logoutInitialState);

export { logout };
