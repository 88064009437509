export const GET_UPLOAD_QUESTION = 'GET_UPLOAD_QUESTION';
export const POST_UPLOAD_QUESTION = 'POST_UPLOAD_QUESTION';
export const PUT_UPLOAD_QUESTION = 'PUT_UPLOAD_QUESTION';
export const SET_UPLOAD_QUESTION = 'SET_UPLOAD_QUESTION';
export const DELETE_UPLOAD_QUESTION = 'DELETE_UPLOAD_QUESTION';

export const GET_UPLOAD_QUESTIONLIST = 'GET_UPLOAD_QUESTIONLIST';
export const SET_UPLOAD_QUESTIONLIST = 'SET_UPLOAD_QUESTIONLIST';
export const GET_UPLOAD_QUESTIONDETAILLIST = 'GET_UPLOAD_QUESTIONDETAILLIST';
export const SET_UPLOAD_QUESTIONDETAILLIST = 'SET_UPLOAD_QUESTIONDETAILLIST';
export const GET_UPLOAD_QUESTIONAPPROVE = 'GET_UPLOAD_QUESTIONAPPROVE';
export const POST_UPLOAD_QUESTIONRETURN = 'GET_UPLOAD_QUESTIONRETURN';

export const POST_UPLOAD_QUESTIONIMAGE = 'POST_UPLOAD_QUESTIONIMAGE';
export const DELETE_UPLOAD_QUESTIONIMAGE = 'DELETE_UPLOAD_QUESTIONIMAGE';

export const POST_UPLOAD_ANSWERIMAGE = 'POST_UPLOAD_ANSWERIMAGE';
export const DELETE_UPLOAD_ANSWERIMAGE = 'DELETE_UPLOAD_ANSWERIMAGE';

export const GET_UPLOAD_LASTQUESTION = 'GET_UPLOAD_LASTQUESTION';
export const SET_UPLOAD_LASTQUESTION = 'SET_UPLOAD_LASTQUESTION';

export const GET_UPLOAD_QUESTIONFILTER = 'GET_UPLOAD_QUESTIONFILTER';
export const SET_UPLOAD_QUESTIONFILTER = 'SET_UPLOAD_QUESTIONFILTER';

export const GET_UPLOAD_SEARCHFILTER = 'GET_UPLOAD_SEARCHFILTER';
export const SET_UPLOAD_SEARCHFILTER = 'SET_UPLOAD_SEARCHFILTER';

export const GET_UPLOAD_QUESTIONCOUNT = 'GET_UPLOAD_QUESTIONCOUNT';
export const SET_UPLOAD_QUESTIONCOUNT = 'SET_UPLOAD_QUESTIONCOUNT';

export const GET_UPLOAD_SELECTEDTAB = 'GET_UPLOAD_SELECTEDTAB';
export const SET_UPLOAD_SELECTEDTAB = 'SET_UPLOAD_SELECTEDTAB';

export const GET_UPLOAD_TRANSLATEQUESTION = 'GET_UPLOAD_TRANSLATEQUESTION';
export const SET_UPLOAD_TRANSLATEQUESTION = 'SET_UPLOAD_TRANSLATEQUESTION';
