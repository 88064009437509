import { memo } from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as CommonSelectors from 'app/redux/selectors';

function CustomLoading() {
  const isLoading = useSelector(CommonSelectors.getLoading);
  return (
    <Backdrop sx={{ zIndex: 5000, color: '#fff' }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default memo(CustomLoading);
