import produce from 'immer';
import * as Types from './types';

const DEFAULT_STATE = {
  navigationFilters: {
    page: 1,
    pageSize: 20,
    sorts: '',
    filters: ''
  },
  filter: {
    firstName: '',
    lastName: ''
  },
  count: 1
};

const dashboard = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_DASHBOARD_RANK:
      state.rank = action.payload;
      return state;
    case Types.SET_DASHBOARD_SCOREDETAIL:
      state.scoreDetail = action.payload;
      return state;
    case Types.SET_DASHBOARD_SUBSCRIPTION:
      state.subscription = action.payload;
      return state;
    case Types.SET_DASHBOARD_LATESTTEST:
      state.latestTest = action.payload;
      return state;
    case Types.SET_DASHBOARD_TESTHISTORY:
      state.testHistory = action.payload;
      return state;
    case Types.SET_DASHBOARD_STUDENTSTATUS:
      state.studentStatus = action.payload;
      return state;
    case Types.SET_DASHBOARD_STAFFHISTORY:
      state.staffHistory = action.payload;
      return state;
    case Types.SET_DASHBOARD_USERSTATUS:
      state.userStatus = action.payload;
      return state;
    case Types.SET_DASHBOARD_USERSTATUSFILTER:
      state.filter = action.payload;
      return state;
    case Types.SET_DASHBOARD_NAVIGATIONFILTER:
      state.navigationFilters = { ...state.navigationFilters, ...action.payload };
      return state;
    case Types.SET_DASHBOARD_NAVIGATIONCOUNT:
      state.count = action.payload;
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { dashboard };
