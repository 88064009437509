import * as Types from 'views/common/redux/types';
import { executeEpic } from '@helpers/utilities';
import { getRefreshToken } from 'views/common/api';
import { refreshToken } from 'views/user/redux/actions';

const getRefreshTokenEpic = (action$) =>
  executeEpic(Types.GET_REFRESHTOKEN_FOR_SECURITY, getRefreshToken, (responseData) => [
    refreshToken.set(responseData?.response?.token)
  ])(action$);

// export const getRefreshTokenEpic = () =>
//   getRefreshToken().subscribe(
//     (res) => {
//       const token = res?.response?.token;
//       if (token) {
//         return { token };
//       }

//       return null;
//     },
//     (err) => {
//       console.log(err);
//       return { err };
//     }
//   );
export default [getRefreshTokenEpic];
