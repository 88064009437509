import produce from 'immer';
import * as Types from './types';

const DEFAULT_STATE = {
  navigationFilters: {
    page: 1,
    pageSize: 20,
    sorts: '-createdAt',
    filters: ''
  },
  count: 1
};

const commonTest = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_TESTINFO_COMMON:
      state.commonTestDetails = action.payload;
      return state;
    case Types.SET_COURSE_COMMON:
      state.courses = action.payload;
      return state;
    case Types.SET_STUDENTCOUNT_COMMON:
      state.count = action.payload;
      return state;
    case Types.SET_NAVIGATIONFILTER_COMMON:
      state.navigationFilters = { ...state.navigationFilters, ...action.payload };
      return state;
    case Types.SET_STUDENT_COMMON:
      if (action.payload) {
        const studentList = [...action.payload];
        state.students = studentList.filter((student) => !student.isTestCompleted);
      } else {
        state.students = {};
      }
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { commonTest };
