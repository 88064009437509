import { memo } from 'react';
import { merge } from 'lodash';
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AnimateGroup } from 'components';
import { BaseOptionChart } from '@helpers/components/Chart';
import ReactApexChart from 'react-apexcharts';
// import ChartHolder from './chartHolder';

// const MyResponsivePie = (testData) => (
//   <PieChart
//     data={testData?.chartData ?? []}
//     sAngle={360}
//     eAngle={0}
//     innRadius={0.5}
//     fillPattern={testData?.chartPattern ?? []}
//     enableRadialLabel={false}
//     colorScheme={PIECHART_COLORSCHEME.category10}
//   />
// );

const CHART_HEIGHT = 270;
const LEGEND_HEIGHT = 50;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

const NewPieChart = (props) => {
  const theme = useTheme();

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.success.main,
      theme.palette.error.main
    ],
    labels: [...props?.data?.chartLabel],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  });
  return (
    <Card>
      <CardHeader title={props?.title} />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="pie"
          height={250}
          options={chartOptions}
          series={props?.data?.chartValue}
        />
      </ChartWrapperStyle>
    </Card>
  );
};

function CustomPieChart(props) {
  const { errorMessage, data, title } = props;
  return (
    <AnimateGroup
      enter={{
        animation: 'transition.slideUpBigIn'
      }}
    >
      {Object.keys(data).length > 0 ? (
        <NewPieChart data={data} title={title} />
      ) : (
        <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {errorMessage}
        </Typography>
      )}
    </AnimateGroup>
  );
}

export default memo(CustomPieChart);
