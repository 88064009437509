import { lazy } from 'react';

export const ExamConfig = {
  routes: [
    {
      path: '/exam',
      exact: true,
      component: lazy(() => import('./sections/questionLayout'))
    },
    {
      path: '/examHome',
      exact: true,
      component: lazy(() => import('./index'))
    },
    {
      path: '/examResult',
      exact: true,
      component: lazy(() => import('./sections/examResult'))
    },
    {
      path: '/examCommon',
      exact: true,
      component: lazy(() => import('./sections/commonExamList'))
    }
  ]
};
