import { callApi } from '@helpers/utilities';

const getCommonTestApi = (commonFilterParam) =>
  callApi({
    method: 'GET',
    url: `/tests/common?${commonFilterParam}`
  }).withAuth();

const putCommonTestApi = (commonTestParam) =>
  callApi({
    method: 'PUT',
    url: `/tests/common`,
    body: { ...commonTestParam }
  }).withAuth();

const getCommonTestDetailApi = (commonFilterParam) =>
  callApi({
    method: 'GET',
    url: `/tests/common/${commonFilterParam}`
  }).withAuth();

const getCommonTestCourseApi = () =>
  callApi({
    method: 'GET',
    url: `/course?QueryType=3&filters=isActive==true`
  }).withAuth();

// const getCommonTestStudentApi = (commonTestId) =>
//   callApi({
//     method: "GET",
//     url: `/tests/common/${commonTestId}/students`,
//   }).withAuth();

const getCommonTestStudentApi = (queryParam) =>
  callApi({
    method: 'GET',
    url: `/tests/history?${queryParam}`
  }).withAuth();

const postReassignTestApi = (reAssignTestParam) =>
  callApi({
    method: 'POST',
    url: `/tests/common/reassign`,
    body: { ...reAssignTestParam }
  }).withAuth();

const getCommonTestActivateApi = (testId) =>
  callApi({
    method: 'GET',
    url: `/tests/common/${testId}/activate`
  }).withAuth();

export {
  getCommonTestApi,
  putCommonTestApi,
  getCommonTestDetailApi,
  getCommonTestCourseApi,
  getCommonTestStudentApi,
  postReassignTestApi,
  getCommonTestActivateApi
};
