import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { push, replace } from 'connected-react-router';
import * as CommonActions from 'app/redux/actions';
import { errorMapper, executeEpic } from '@helpers/utilities';
import { removeToken } from '@helpers/utilities/storageHelper';
import { auth } from 'views/common/redux/actions';
import {
  getClientApi,
  avalabilityPhoneApi,
  avalabilityEmailApi,
  registerUserApi,
  validateOtpApi,
  registerCompleteApi,
  loginApi,
  logoutApi,
  forgetPasswordInitateApi,
  forgetPasswordCompleteApi,
  testOtpApi,
  resendOtpApi
} from '../api';

import {
  client,
  availabilityEmail,
  availabilityPhone,
  otp,
  register,
  registerComplete,
  login,
  forgetPasswordInitiate,
  fpActiveStep,
  forgetPasswordOtp,
  testOtp
} from './actions';
import * as Types from './types';

const clientEpic = (action$) =>
  executeEpic(Types.GET_USER_CLIENT, getClientApi, (responseData) => [
    client.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const availabilityPhoneEpic = (action$) =>
  executeEpic(Types.GET_USER_AVAILABILITYPHONE, avalabilityPhoneApi, (responseData) => [
    availabilityPhone.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const availabilityEmailEpic = (action$) =>
  executeEpic(Types.GET_USER_AVAILABILITYEMAIL, avalabilityEmailApi, (responseData) => [
    availabilityEmail.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const userRegistrationEpic = (action$) =>
  executeEpic(Types.POST_USER_REGISTER, registerUserApi, (responseData) => [
    register.set(responseData.response),
    push(`/signUpComplete?`),
    CommonActions.stopLoading()
  ])(action$);

const otpVerifyEpic = (action$) =>
  executeEpic(Types.GET_USER_OTP, validateOtpApi, (responseData) => [
    otp.set(responseData.response),
    register.set({}),
    CommonActions.stopLoading()
  ])(action$);

const userRegistrationCompleteEpic = (action$) =>
  executeEpic(Types.POST_USER_REGISTERCOMPLETE, registerCompleteApi, (responseData) => [
    registerComplete.set(responseData.response),
    otp.set({}),
    register.set({}),
    CommonActions.setIsUserAuthorized(true),
    CommonActions.stopLoading(),
    CommonActions.showAlert('You have successfully registered..', 'success'),
    // push(`/profile?id=${responseData?.response?.id}`)
    push('/dashboard')
  ])(action$);

const loginEpic = (action$) =>
  executeEpic(Types.POST_USER_LOGIN, loginApi, (responseData) => {
    localStorage.setItem('__t', responseData?.response?.token);
    return [
      login.set(responseData.response),
      CommonActions.stopLoading(),
      CommonActions.setIsUserAuthorized(true),
      CommonActions.showAlert('You have successfully logged in.', 'success'),
      push(
        responseData?.response?.userType === 4 ||
          responseData?.response?.userType === 6 ||
          responseData?.response?.userType === 7
          ? '/upload'
          : '/dashboard'
      )
    ];
  })(action$);

const logoutEpic = (action$) =>
  action$.pipe(
    ofType(Types.GET_USER_LOGOUT),
    mergeMap(() =>
      logoutApi().pipe(
        mergeMap(() => {
          removeToken();
          return of(
            auth.set(null),
            CommonActions.stopLoading(),
            CommonActions.showAlert('Logged out successfully', 'success'),
            { type: 'RESET_APP' },
            replace(`/login?`)
          );
        }),
        catchError((error) =>
          of(
            CommonActions.errorResponse(error.response),
            CommonActions.stopLoading(),
            CommonActions.showAlert(errorMapper(error.response), 'error'),
            { type: 'RESET_APP' },
            replace(`/login?`)
          )
        )
      )
    )
  );

const fpInitiateEpic = (action$) =>
  executeEpic(Types.POST_USER_FORGETPASSWORDINITIATE, forgetPasswordInitateApi, (responseData) => [
    forgetPasswordInitiate.set(responseData.response),
    fpActiveStep.set(1),
    CommonActions.stopLoading()
  ])(action$);

const fpOtpVerifyEpic = (action$) =>
  executeEpic(Types.GET_USER_FORGETPASSWORDOTP, validateOtpApi, (responseData) => [
    forgetPasswordInitiate.set({}),
    forgetPasswordOtp.set(responseData.response),
    CommonActions.stopLoading(),
    fpActiveStep.set(2)
  ])(action$);

const fpCompleteEpic = (action$) =>
  executeEpic(Types.POST_USER_FORGETPASSWORDCOMPLETE, forgetPasswordCompleteApi, () => [
    CommonActions.stopLoading(),
    CommonActions.showAlert(
      'Password has been changed successfully. Please login with new password',
      'success'
    ),
    replace(`/login?`),
    forgetPasswordOtp.set({}),
    fpActiveStep.set(0)
  ])(action$);

const testOtpEpic = (action$) =>
  executeEpic(Types.POST_USER_TESTOTP, testOtpApi, (responseData) => [
    testOtp.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const resendOtpEpic = (action$) =>
  executeEpic(Types.GET_USER_RESENDOTP, resendOtpApi, (responseData) => [
    otp.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

export default [
  clientEpic,
  availabilityPhoneEpic,
  availabilityEmailEpic,
  userRegistrationEpic,
  otpVerifyEpic,
  userRegistrationCompleteEpic,
  loginEpic,
  logoutEpic,
  testOtpEpic,
  fpInitiateEpic,
  fpOtpVerifyEpic,
  fpCompleteEpic,
  resendOtpEpic
];
