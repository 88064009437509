import { lazy } from 'react';

export const BulkImportConfig = {
  routes: [
    {
      path: '/user',
      exact: true,
      component: lazy(() => import('./sections/uploadTab'))
    },
    {
      path: '/user/confirmation/:user',
      exact: true,
      component: lazy(() => import('./sections/userUploadConfirmation'))
    }
  ]
};
