import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Avatar } from '@mui/material';
import Single from 'assets/img/single.png';
import Multiple from 'assets/img/multiple.png';
import Subjective from 'assets/img/subjective.png';
import Reasoning from 'assets/img/reasoning.png';
import Comprehensive from 'assets/img/comprehensive.png';
import Matrix from 'assets/img/matrix.png';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`
};

const StyledChip = styled(Chip)(() => ({
  [`& .${classes.root}`]: {
    height: 40,
    borderRadius: 20,
    padding: '0px 5px',
    '& span': {
      fontSize: 13
    }
  }
}));

function QuestionPattern(props) {
  const { questionPattern } = props;

  const getLabel = (pattern) => {
    switch (pattern) {
      case 1:
        return 'MCQ with Single Correct';
      case 2:
        return 'Subjective Pattern';
      case 3:
        return 'Comprehensive Pattern';
      case 4:
        return 'Matrix Type';
      case 5:
        return 'Reasoning Type';
      case 6:
        return 'MCQ with Multi Correct';
      default:
        return 'MCQ Questions';
    }
  };

  const getIcon = (pattern) => {
    switch (pattern) {
      case 1:
        return Single;
      case 2:
        return Subjective;
      case 3:
        return Comprehensive;
      case 4:
        return Matrix;
      case 5:
        return Reasoning;
      case 6:
        return Multiple;
      default:
        return Single;
    }
  };
  return (
    <StyledChip
      avatar={<Avatar style={{ height: 30, width: 30 }} src={getIcon(questionPattern)} />}
      label={getLabel(questionPattern)}
    />
  );
}

export default memo(QuestionPattern);
