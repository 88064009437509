export const GET_TESTINFO_COMMON = 'GET_TESTINFO_COMMON';
export const POST_TESTINFO_COMMON = 'POST_TESTINFO_COMMON';
export const SET_TESTINFO_COMMON = 'SET_TESTINFO_COMMON';
export const PUT_TESTINFO_COMMON = 'PUT_TESTINFO_COMMON';

export const GET_NAVIGATIONFILTER_COMMON = 'GET_NAVIGATIONFILTER_COMMON';
export const SET_NAVIGATIONFILTER_COMMON = 'SET_NAVIGATIONFILTER_COMMON';

export const GET_STUDENTCOUNT_COMMON = 'GET_STUDENTCOUNT_COMMON';
export const SET_STUDENTCOUNT_COMMON = 'SET_STUDENTCOUNT_COMMON';

export const GET_TESTDETAILINFO_COMMON = 'GET_TESTDETAILINFO_COMMON';
export const SET_TESTDETAILINFO_COMMON = 'SET_TESTDTAILINFO_COMMON';

export const GET_COURSE_COMMON = 'GET_COURSE_COMMON';
export const SET_COURSE_COMMON = 'SET_COURSE_COMMON';

export const GET_STUDENT_COMMON = 'GET_STUDENT_COMMON';
export const SET_STUDENT_COMMON = 'SET_STUDENT_COMMON';

export const POST_REASSIGN_COMMON = 'POSTREASSIGN_COMMON';

export const GET_ACTIVATE_COMMON = 'GET_ACTIVATE_COMMON';
