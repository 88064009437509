import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDialog, CustomTextField } from 'components';
import { loginApi } from 'views/user/api';
import { login } from 'views/user/redux/actions';
// import { profileInfo } from 'views/profile/redux/actions';
import * as CommonActions from 'app/redux/actions';
import { v4 } from 'uuid';

function Authorize401() {
  const [dialogId, setDialogId] = useState(undefined);
  const firstName = useSelector((state) => state?.auth?.data?.FirstName ?? undefined);
  const clientIdentity = useSelector((state) => state?.auth?.data?.ClientUrl ?? undefined);
  const isUserAuthorized = useSelector((state) => state?.common?.isUserAuthorized);
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserAuthorized === false) {
      setDialogId(v4());
    }
  }, [isUserAuthorized]);

  const handleToken = (e) => setToken(e.target.value);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e, closer) => {
    if (!token || !password) {
      return;
    }

    const loginResponse = loginApi({
      userName: token,
      password,
      clientIdentity
    });
    loginResponse.subscribe(
      (res) => {
        const userData = res?.response;
        if (userData) {
          dispatch(closer);
          setPassword('');
          setDialogId(undefined);

          setTimeout(() => {
            dispatch(login.set(userData));
            // dispatch(profileInfo.get());
            dispatch(CommonActions.showAlert('Login successful', 'success'));
          }, 0);
        }
      },
      (err) => {
        dispatch(CommonActions.showAlert(err?.response?.errorMessage, 'error'));
        e.preventDefault();
      }
    );
  };

  if (!dialogId) {
    return null;
  }

  return (
    <CustomDialog
      id={dialogId}
      disableEscKey
      disableBackDrop
      disableAutoFocus
      handlePrimaryButtonClick={handleLogin}
      dialogTitle="Session expired"
      maxWidth="sm"
      dialogContent={`Hi ${firstName}, your session has beed expired. Please login again to continue.`}
      component={
        <>
          <CustomTextField
            autoFocus
            margin="dense"
            id="name"
            label="Email or Mobile"
            type="text"
            placeholder="Enter email or mobile"
            fullWidth
            value={token}
            onChange={handleToken}
          />

          <CustomTextField
            margin="dense"
            id="name"
            label="Password"
            type="password"
            placeholder="Enter password"
            fullWidth
            value={password}
            onChange={handlePassword}
          />
        </>
      }
      buttonPrimaryText={password?.length > 0 ? 'Login' : undefined}
    />
  );
}

export default memo(Authorize401);
