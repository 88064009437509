import 'date-fns';
import { styled } from '@mui/material/styles';
import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { CustomTextField, Alert } from 'components';
import { resendOtp } from 'views/user/redux/actions';
import * as CommonActions from 'app/redux/actions';
import { verifyComplete, confirmContactChange } from '../redux/actions';
import { getProfileOtpData, getUserInfo, getOtpVerificationStauts } from '../redux/selectors';

const PREFIX = 'otpPage';

const classes = {
  signUpContainer: `${PREFIX}-signUpContainer`,
  signUpBtn: `${PREFIX}-signUpBtn`,
  pl10: `${PREFIX}-pl10`,
  loginForm: `${PREFIX}-loginForm`,
  datePicker: `${PREFIX}-datePicker`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.signUpContainer}`]: {
    display: 'flex',
    justifyContent: 'center'
  },

  [`& .${classes.signUpBtn}`]: {
    width: 100
  },

  [`& .${classes.pl10}`]: {
    paddingLeft: 10
  },

  [`& .${classes.loginForm}`]: {
    '& > div': {
      padding: 8
    }
  },

  [`& .${classes.datePicker}`]: {
    '& > div': {
      borderRadius: 5,
      marginTop: 0
    },
    '& input': {
      height: '45px',
      padding: '0px 14px'
    },
    '& label': {
      height: '45px',
      top: '-3px'
    },
    '& label[data-shrink=true]': {
      top: 0
    }
  }
}));

function OtpPage(props) {
  const dispatch = useDispatch();
  const otpDetails = useSelector(getProfileOtpData);
  const otpStatus = useSelector(getOtpVerificationStauts);
  const user = useSelector(getUserInfo);
  const [otpValue, setOtpValue] = useState('');
  const [isOtpSent, setOtpSent] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (otpDetails?.id) setOtpSent(true);
  }, [otpDetails]);

  const handleSubmit = () => {
    dispatch(CommonActions.startLoading());
    if (props.isVerify) {
      dispatch(verifyComplete.post({ code: otpValue, id: otpDetails?.id }));
      props.onSubmit();
    } else {
      dispatch(confirmContactChange.post({ code: otpValue, id: otpDetails?.id }));
      props.onSubmit();
    }
  };

  const showSubmit = () => otpValue !== '' || (user?.isEmailVerified && user?.isMobileVerified);

  const handleResetOTP = () => {
    dispatch(CommonActions.startLoading());
    dispatch(resendOtp.get(otpDetails?.id));
  };

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {isOtpSent && !otpStatus && (
            <Alert alertType="success" message="OTP Sent successfully." />
          )}
        </Grid>
        {otpStatus && (
          <Grid item xs={12} sm={12} md={12}>
            <Alert alertType="success" message="OTP verified successfully." />
          </Grid>
        )}
        {!otpStatus && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CustomTextField
                variant="outlined"
                fullWidth
                label="OTP"
                required
                inputProps={{ maxLength: 50 }}
                value={otpValue}
                onChange={(e) => setOtpValue(e.target.value)}
              />
            </Grid>
            <Grid item container justifyContent="flex-end" xs={12} sm={12} md={12} spacing={2}>
              {minutes !== 0 || seconds !== 0 ? (
                <Typography style={{ paddingLeft: 4 }} variant="caption">{`${minutes.toLocaleString(
                  'en-US',
                  {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  }
                )}:${seconds.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })}`}</Typography>
              ) : (
                <Link component="button" onClick={handleResetOTP}>
                  Resend OTP
                </Link>
              )}
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.signUpBtn}
                  disabled={!showSubmit()}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Root>
  );
}

export default memo(OtpPage);
