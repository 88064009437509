import { replace } from 'connected-react-router';
import * as CommonActions from 'app/redux/actions';
import { executeEpic } from '@helpers/utilities';
import { showAlert } from 'app/redux/actions';
import { examRule, exams } from 'views/common/redux/actions';
import { client, unit, courseData } from './actions';
import * as Types from './types';
import { getClientApi, getUnitApi, postCourseApi, postAssignCourseApi } from '../api';

const COURSE_DEFAULT_STATE = {
  course: {
    clientId: -1,
    commonTestLimit: 0,
    description: '',
    discPerc: 0,
    endDate: '',
    examId: -1,
    hasCommonTest: true,
    hasEbook: false,
    hasLimit: true,
    hasOnlineClass: false,
    hasPersonalTest: true,
    hasPracticeTest: true,
    isFree: false,
    isUnitLimit: false,
    maxSubscriber: 0,
    mrp: 0,
    name: '',
    price: 0,
    startDate: '',
    subscriberCount: 0,
    summary: ''
  },
  subjectLimits: [],
  unitLimits: [],
  unitSections: []
};

const clientCourseEpic = (action$) =>
  executeEpic(Types.GET_CLIENT_COURSE, getClientApi, (responseData) => [
    client.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const unitCourseEpic = (action$) =>
  executeEpic(Types.GET_UNIT_COURSE, getUnitApi, (responseData) => [
    unit.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const postCourseEpic = (action$) =>
  executeEpic(Types.POST_COURSEDATA_COURSE, postCourseApi, () => [
    courseData.set(COURSE_DEFAULT_STATE),
    examRule.set(),
    exams.set(),
    replace('/dashboard'),
    showAlert('Course created successfully', 'success'),
    CommonActions.stopLoading()
  ])(action$);

const postAssignCourseEpic = (action$) =>
  executeEpic(Types.POST_ASSIGN_COURSE, postAssignCourseApi, () => [
    replace('/dashboard'),
    showAlert('Course assigned successfully', 'success'),
    CommonActions.stopLoading()
  ])(action$);

export default [clientCourseEpic, unitCourseEpic, postCourseEpic, postAssignCourseEpic];
