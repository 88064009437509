import { callApi } from '@helpers/utilities';

export const getProfile = () => callApi({ method: 'GET', url: `/profile` }).withAuth();
export const putUserInfo = (body) => callApi({ method: 'PUT', url: `/profile`, body }).withAuth();
export const postPersonalInfo = (body) =>
  callApi({ method: 'POST', url: `/profiles/personal`, body }).withAuth();
export const putPersonalInfo = (body) =>
  callApi({ method: 'PUT', url: `/profiles/personal`, body }).withAuth();
export const postStudentInfo = (body) =>
  callApi({ method: 'POST', url: '/students', body }).withAuth();
export const putStudentInfo = (body) =>
  callApi({ method: 'PUT', url: '/students', body }).withAuth();
export const postChangePassword = (body) =>
  callApi({ method: 'POST', url: '/users/changePassword', body }).withAuth();
export const postContactVerifyApi = (body) =>
  callApi({ method: 'POST', url: `/profile/ContactVerfify`, body }).withAuth();
export const postVerifyCompleteApi = (body) =>
  callApi({ method: 'POST', url: `/profile/ContactVerfify/Confirm`, body }).withAuth();
export const postRequestContactChangeApi = (body) =>
  callApi({ method: 'POST', url: '/profile/ContactChanges', body }).withAuth();
export const postConfirmContactChangeApi = (body) =>
  callApi({ method: 'POST', url: `/profile/ContactChanges/confirm`, body }).withAuth();
export const postStaffInfoApi = (body) =>
  callApi({ method: 'POST', url: '/staffs', body }).withAuth();
export const putStaffInfoApi = (body) =>
  callApi({ method: 'PUT', url: '/staffs', body }).withAuth();
