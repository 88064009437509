import { callApi } from '@helpers/utilities';

export const getTestInfoApi = ({ queryParam }) =>
  callApi({
    method: 'GET',
    url: `/tests/history?${queryParam}`
  }).withAuth();

export const getTestAnalysisApi = (testId) =>
  callApi({
    method: 'GET',
    url: `/tests/${testId}/analysis`
  }).withAuth();
export const getTestDetailApi = (testId) =>
  callApi({
    method: 'GET',
    url: `/tests/${testId}`
  }).withAuth();
export const getStudentInfoApi = (queryParam) =>
  callApi({
    method: 'GET',
    url: `/course/subscribers?${queryParam}`
  }).withAuth();
export const getStudentCourseApi = (studentId) =>
  callApi({
    method: 'GET',
    url: `/course/${studentId}/Course`
  }).withAuth();
