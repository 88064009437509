export const GET_DASHBOARD_RANK = 'GET_DASHBOARD_RANK';
export const SET_DASHBOARD_RANK = 'SET_DASHBOARD_RANK';

export const GET_DASHBOARD_SCOREDETAIL = 'GET_DASHBOARD_SCOREDETAIL';
export const SET_DASHBOARD_SCOREDETAIL = 'SET_DASHBOARD_SCOREDETAIL';

export const GET_DASHBOARD_SUBSCRIPTION = 'GET_DASHBOARD_SUBSCRIPTION';
export const SET_DASHBOARD_SUBSCRIPTION = 'SET_DASHBOARD_SUBSCRIPTION';

export const GET_DASHBOARD_LATESTTEST = 'GET_DASHBOARD_LATESTTEST';
export const SET_DASHBOARD_LATESTTEST = 'SET_DASHBOARD_LATESTTEST';

export const GET_DASHBOARD_TESTHISTORY = 'GET_DASHBOARD_TESTHISTORY';
export const SET_DASHBOARD_TESTHISTORY = 'SET_DASHBOARD_TESTHISTORY';

export const GET_DASHBOARD_STUDENTSTATUS = 'GET_DASHBOARD_STUDENTSTATUS';
export const SET_DASHBOARD_STUDENTSTATUS = 'SET_DASHBOARD_STUDENTSTATUS';

export const GET_DASHBOARD_STAFFHISTORY = 'GET_DASHBOARD_STAFFHISTORY';
export const SET_DASHBOARD_STAFFHISTORY = 'SET_DASHBOARD_STAFFHISTORY';

export const GET_DASHBOARD_USERSTATUS = 'GET_DASHBOARD_USERSTATUS';
export const SET_DASHBOARD_USERSTATUS = 'SET_DASHBOARD_USERSTATUS';

export const GET_DASHBOARD_STUDENTSTATUSEXCEL = 'GET_DASHBOARD_STUDENTSTATUSEXCEL';
export const SET_DASHBOARD_STUDENTSTATUSEXCEL = 'SET_DASHBOARD_STUDENTSTATUSEXCEL';

export const GET_DASHBOARD_STAFFSTATUSEXCEL = 'GET_DASHBOARD_STAFFSTATUSEXCEL';
export const SET_DASHBOARD_STAFFSTATUSEXCEL = 'SET_DASHBOARD_STAFFSTATUSEXCEL';

export const GET_DASHBOARD_USERSTATUSFILTER = 'GET_DASHBOARD_USERSTATUSFILTER';
export const SET_DASHBOARD_USERSTATUSFILTER = 'SET_DASHBOARD_USERSTATUSFILTER';

export const GET_DASHBOARD_NAVIGATIONFILTER = 'GET_DASHBOARD_NAVIGATIONFILTER';
export const SET_DASHBOARD_NAVIGATIONFILTER = 'SET_DASHBOARD_NAVIGATIONFILTER';

export const GET_DASHBOARD_NAVIGATIONCOUNT = 'GET_DASHBOARD_NAVIGATIONCOUNT';
export const SET_DASHBOARD_NAVIGATIONCOUNT = 'SET_DASHBOARD_NAVIGATIONCOUNT';
