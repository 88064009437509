import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_PROFILE_${type?.toUpperCase()}`,
  (type) => `SET_PROFILE_${type?.toUpperCase()}`,
  (type) => `POST_PROFILE_${type?.toUpperCase()}`,
  (type) => `PUT_PROFILE_${type?.toUpperCase()}`
);

const profileInfo = properties('profileInfo');
const userInfo = properties('userInfo');
const personalInfo = properties('personalInfo');
const studentInfo = properties('studentInfo');
const changePassword = properties('changePassword');
const contactVerify = properties('contactverify');
const verifyComplete = properties('verifyComplete');
const requestContactChange = properties('requestContactChange');
const confirmContactChange = properties('confirmContactChange');
const staffInfo = properties('staffInfo');

export {
  profileInfo,
  userInfo,
  personalInfo,
  studentInfo,
  changePassword,
  contactVerify,
  verifyComplete,
  requestContactChange,
  confirmContactChange,
  staffInfo
};
