import { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme, styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { routes } from 'app/config/route-config';
import {
  CustomHeader,
  CustomSideBar,
  CustomLoading,
  Authorize401,
  NoResultFound,
  CustomDialog as Dialog
} from 'components';
import {
  StudentRoutes,
  AdminRoutes,
  SuperAdminRoutes,
  DataEntryRoutes,
  StaffRoutes
} from 'app/config/routes';
import NaviraLogo from 'assets/img/naviralogo.png';
import Symbol from 'assets/img/navira_symbol.png';
import { NaviraAlert } from '@helpers';
import { useIdleTimer } from 'react-idle-timer';
import { logout } from 'views/user/redux/actions';
import { startLoading } from 'app/redux/actions';
import { getLogoutFlag } from 'views/common/redux/selectors';
import { flag } from 'views/common/redux/actions';
import useStudentValidation from '@helpers/hooks/studentValidationHook';
import useStaffValidation from '@helpers/hooks/staffValidationHook';
import { v4 } from 'uuid';
import ProfileVerification from 'views/profile/sections/profileVerification';
import NaviraSuspense from '../NaviraSuspense/naviraSuspense';

const renderRoutes = () => (
  <>
    {routes?.map((route, key) => {
      const { path, exact, component: OtherComponent } = route;
      return (
        <Route key={key} {...{ path, exact, key }}>
          <OtherComponent />
        </Route>
      );
    })}
  </>
);

const Root = styled('div')({
  display: 'flex'
});

const Container = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1)
  }
}));

const DEFAULT_STATE = {
  title: '',
  description: '',
  primaryText: '',
  secondaryText: '',
  showDialog: undefined,
  flow: ''
};

function AuthLayout() {
  const { pathname } = useSelector((state) => state?.router?.location);
  const [isStudentValid] = useStudentValidation();
  const [isStaffValid] = useStaffValidation();
  // Component Styles
  const theme = useTheme();
  const dispatch = useDispatch();
  // Reference Object
  // const mainPanel = createRef();
  // Store Selectors
  const userType = useSelector((state) => state?.auth?.data?.UserType);
  const logoutFlag = useSelector(getLogoutFlag);
  // Local State Object
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [dialogObject, setDialogObject] = useState(DEFAULT_STATE);
  const [showIdleWarning, setShowIdleWarning] = useState(false);
  const [showIdleTimeoutWarning, setIdleTimeoutWarning] = useState(false);
  const [showProfileWarning, setProfileWarning] = useState(false);

  useEffect(() => {
    if (showIdleWarning)
      setDialogObject({
        title: 'Idle Warning!',
        description: 'Session inactive. Will expire shortly, Click OK to continue',
        primaryText: 'Ok',
        secondaryText: '',
        showDialog: showIdleWarning,
        flow: 'IdleTimeWarning'
      });
    else if (showIdleTimeoutWarning)
      setDialogObject({
        title: 'Inactive Session!',
        description: 'Session expired. Login again',
        primaryText: 'Ok',
        secondaryText: '',
        showDialog: showIdleTimeoutWarning,
        flow: 'IdleTimeout'
      });
    else if (showProfileWarning)
      setDialogObject({
        title: 'Profile Completion!',
        description: 'Complete your profile to access other features',
        showDialog: showProfileWarning,
        component: <ProfileVerification />,
        flow: 'Profile'
      });
  }, [showIdleWarning, showIdleTimeoutWarning, showProfileWarning]);

  useEffect(() => {
    if (!isStudentValid) {
      setProfileWarning(v4());
    } else {
      setProfileWarning(false);
      setDialogObject(DEFAULT_STATE);
    }
  }, [isStudentValid]);

  useEffect(() => {
    if (showIdleWarning) {
      const timeout = setTimeout(() => {
        if (showIdleWarning) {
          setShowIdleWarning(false);
          setIdleTimeoutWarning(v4());
        }
      }, 1000 * 60 * 2);
      return () => clearTimeout(timeout);
    }
  }, [showIdleWarning]);

  useEffect(() => {
    if (logoutFlag) {
      setDialogObject({
        title: 'Confirm Logout!',
        description: 'Are you sure you want to logout?',
        primaryText: 'Ok',
        secondaryText: 'Cancel',
        showDialog: logoutFlag,
        flow: 'LogoutSession'
      });
    } else {
      setDialogObject(DEFAULT_STATE);
    }
  }, [logoutFlag]);

  const handleOnIdle = (event) => {
    console.log('user is idle', event);
    // eslint-disable-next-line no-use-before-define
    console.log('last active', getLastActiveTime());
    setShowIdleWarning(v4());
  };

  const handleOnActive = (event) => {
    console.log('user is active', event);
    // eslint-disable-next-line no-use-before-define
    console.log('time remaining', getRemainingTime() / 60000);
  };

  const handleOnAction = (e) => {
    console.log('user did something', e);
    // eslint-disable-next-line no-use-before-define
    console.log('time remaining', getRemainingTime() / 60000);
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * 18,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    stopOnIdle: true,
    debounce: 500
  });

  const handleDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };
  const handleDrawerMobile = () => {
    setMobileOpen(!mobileOpen);
    console.log(`Mobile Drawer Click : ${mobileOpen}`);
  };
  const handleListClick = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    } else if (desktopOpen) {
      setDesktopOpen(!desktopOpen);
    }
  };
  const mainRoutes = () => {
    // Sidebar menu will be loaded based the UserType
    switch (userType) {
      case 'SuperAdmin':
        return SuperAdminRoutes;
      case 'Admin':
        return AdminRoutes;
      case 'Uploader':
      case 'DataLead':
      case 'DataSME':
        return DataEntryRoutes;
      case 'Student':
        return StudentRoutes;
      case 'Staff':
        return StaffRoutes;
      default:
        return [];
    }
  };

  const onPrimaryClick = (e, closer) => {
    if (dialogObject.title) {
      switch (dialogObject.flow) {
        case 'IdleTimeWarning':
          setShowIdleWarning(false);
          reset();
          dispatch(closer);
          setDialogObject(DEFAULT_STATE);
          break;
        case 'IdleTimeout':
          setIdleTimeoutWarning(false);
          dispatch(closer);
          setDialogObject(DEFAULT_STATE);
          dispatch(startLoading());
          dispatch(logout.get());
          break;
        case 'LogoutSession':
          dispatch(flag.set(false));
          dispatch(closer);
          setDialogObject(DEFAULT_STATE);
          dispatch(startLoading());
          dispatch(logout.get());
          break;
        default:
          setShowIdleWarning(false);
          setIdleTimeoutWarning(false);
          dispatch(flag.set(false));
      }
    }
  };

  const onSecondaryClick = (e, closer) => {
    if (dialogObject.title) {
      switch (dialogObject.flow) {
        case 'LogoutSession':
          dispatch(flag.set(false));
          dispatch(closer);
          break;
        default:
          dispatch(flag.set(false));
      }
    }
  };

  /* This block of code will execute rest all other routes. 
    This is the place where we are our header, sidebar and footer components are loaded */
  return (
    <NaviraSuspense>
      <NaviraAlert />
      <CustomLoading />
      <Authorize401 />
      <Root>
        <CustomSideBar
          routes={mainRoutes()}
          logoText="Navira"
          logo={NaviraLogo}
          image={Symbol}
          handleListItemClick={handleListClick}
          handleDrawerToggle={handleDrawerMobile}
          open={desktopOpen}
          mobileOpen={mobileOpen}
        />
        <CustomHeader
          handleToggle={handleDrawerToggle}
          handleMobileToggle={handleDrawerMobile}
          open={desktopOpen}
        />
        <Container>
          <Toolbar />
          <Dialog
            id={dialogObject.showDialog}
            open={dialogObject.showDialog}
            dialogTitle={dialogObject.title}
            dialogContent={dialogObject.description}
            component={dialogObject.component}
            buttonPrimaryText={dialogObject.primaryText}
            buttonSecondaryText={dialogObject?.secondaryText}
            handlePrimaryButtonClick={onPrimaryClick}
            handleSecondaryButtonClick={onSecondaryClick}
            maxWidth="sm"
            disableEscKey
            disableBackDrop
          />
          <NoResultFound />
          <div
            style={{
              width: '100%',
              minHeight: '82vh',
              height: 'auto',
              borderRadius: 2,
              padding: '15px 0px 0px 0px',
              background: theme.palette.background.paper,
              opacity: isStaffValid || (!isStaffValid && pathname === '/profile') ? 1 : 0.5,
              pointerEvents:
                isStaffValid || (!isStaffValid && pathname === '/profile') ? 'fill' : 'none'
            }}
          >
            <Switch>{renderRoutes()}</Switch>
          </div>
        </Container>
      </Root>
    </NaviraSuspense>
  );
}

export default AuthLayout;
