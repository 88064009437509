import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_HISTORY`,
  (type) => `SET_${type?.toUpperCase()}_HISTORY`,
  (type) => `POST_${type?.toUpperCase()}_HISTORY`,
  (type) => `PUT_${type?.toUpperCase()}_HISTORY`
);

const testInfo = properties('testInfo');
const testInfoCount = properties('testInfoCount');
const navigationFilter = properties('navigationFilter');
const analysisInfo = properties('analysisInfo');
const testDetail = properties('testDetail');
const currentQuestion = properties('currentQuestion');
const filter = properties('filter');
const studentInfo = properties('studentInfo');
const studentCourse = properties('studentCourse');
const individualFilter = properties('individualFilter');
const nameFilter = properties('nameFilter');

export {
  testInfo,
  testInfoCount,
  navigationFilter,
  analysisInfo,
  testDetail,
  currentQuestion,
  filter,
  studentInfo,
  studentCourse,
  individualFilter,
  nameFilter
};
