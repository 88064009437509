import { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

function CustomAlert(props) {
  const { title, alertType, messageArray, message, ...others } = props;
  return (
    <Root>
      <Alert severity={alertType} {...others}>
        <AlertTitle>{title}</AlertTitle>
        {messageArray?.length >= 1
          ? messageArray?.map((errorString, index) => (
              <Typography key={index}>{errorString}</Typography>
            ))
          : null}
        {message}
      </Alert>
    </Root>
  );
}

export default memo(CustomAlert);
CustomAlert.propTypes = {
  title: PropTypes.string,
  alertType: PropTypes.string,
  messageArray: PropTypes.array,
  message: PropTypes.string
};
