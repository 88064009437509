import Upload from 'assets/img/module/upload.png';

const dataEntryRoutes = [
  {
    path: '/upload',
    name: 'Upload',
    icon: Upload
  }
];

export default dataEntryRoutes;
