export const GET_COURSEDATA_COURSE = 'GET_COURSEDATA_COURSE';
export const SET_COURSEDATA_COURSE = 'SET_COURSEDATA_COURSE';
export const POST_COURSEDATA_COURSE = 'POST_COURSEDATA_COURSE';

export const GET_CLIENT_COURSE = 'GET_CLIENT_COURSE';
export const SET_CLIENT_COURSE = 'SET_CLIENT_COURSE';

export const GET_UNIT_COURSE = 'GET_UNIT_COURSE';
export const SET_UNIT_COURSE = 'SET_UNIT_COURSE';

export const POST_ASSIGN_COURSE = 'POST_ASSIGN_COURSE';
export const SET_ASSIGN_COURSE = 'SET_ASSIGN_COURSE';
