import { callApi } from '@helpers/utilities';

export const rankApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/rank`
  }).withAuth();

export const scoreDetailApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/scoreDetails`
  }).withAuth();

export const subscriptionDetailApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/subscription`
  }).withAuth();

export const lastTestDetailApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/test`
  }).withAuth();

export const testHisotryApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/testHistory`
  }).withAuth();

export const studentStatusApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/studentStatus`
  }).withAuth();

export const staffTestHistoryApi = () =>
  callApi({
    method: 'GET',
    url: `/dashborad/staffTestStatus`
  }).withAuth();

export const userStatusApi = (statusParam) =>
  callApi({
    method: 'GET',
    url: `/users/status?${statusParam}`
  }).withAuth();

export const studentStatusExcelApi = (param) =>
  callApi({
    method: 'GET',
    url: `/users/student/statusexcel?clientId=${param}`,
    responseType: 'blob'
  }).withAuth();

export const staffStatusExcelApi = (param) =>
  callApi({
    method: 'GET',
    url: `/users/staff/statusexcel?clientId=${param}`,
    responseType: 'blob'
  }).withAuth();
