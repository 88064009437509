import { lazy } from 'react';

export const EventsConfig = {
  routes: [
    {
      path: '/events',
      exact: true,
      component: lazy(() => import('./sections/events'))
    }
  ]
};
