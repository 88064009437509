import { callApi } from '@helpers/utilities';

const callerAuth = (method, url) => callApi({ method, url }).withAuth();

const getDocumentApi = (queryParams) => callerAuth('GET', `/documents?${queryParams}`);

const getQuestionApi = (queryParams) => callerAuth('GET', `/questions?${queryParams}`);

const getQuestionListApi = (queryParams) =>
  callerAuth('GET', `/questions?QueryType=3&${queryParams}`);

const getCourseApi = (queryParam) => callerAuth('GET', `/course?${queryParam}`);

const getSubjectApi = () => callerAuth('GET', '/masters/subjects');

const getUnitApi = () => callerAuth('GET', '/masters/units?QueryType=3');

const getCommonTestListApi = (testParam) => callerAuth('GET', `/tests/common?${testParam}`);

const getCommonTestApi = (testParam) => callerAuth('GET', `/tests/common/${testParam?.testId}`);

const postCommonTestApi = ({ examParam, testId }) =>
  callApi({
    method: 'POST',
    url: testId ? `/tests/common?sourceTestId=${testId}` : `/tests/common`,
    body: { ...examParam }
  }).withAuth();

const putCommonTestApi = (examParam) =>
  callApi({
    method: 'PUT',
    url: `/tests/common`,
    body: { ...examParam }
  }).withAuth();

const putQuestionUpdateApi = (question) =>
  callApi({
    method: 'PUT',
    url: `/questions`,
    body: { ...question }
  }).withAuth();

const deleteCommonTestApi = (testId) => callerAuth('DELETE', `/tests/common/${testId}`);

const postTestQuestionApi = ({ testId, questionObject }) =>
  callApi({
    method: 'POST',
    url: `/tests/common/${testId}/question`,
    body: [...questionObject]
  }).withAuth();

const deleteTestQuestionApi = ({ testId, question }) =>
  callApi({
    method: 'DELETE',
    url: `/tests/common/${testId}/question`,
    body: [question]
  }).withAuth();

const getCommonTestActivateApi = (testId) =>
  callApi({
    method: 'GET',
    url: `/tests/common/${testId}/activate`
  }).withAuth();

export {
  getDocumentApi,
  getCourseApi,
  getQuestionApi,
  getQuestionListApi,
  getSubjectApi,
  getUnitApi,
  postCommonTestApi,
  getCommonTestApi,
  putCommonTestApi,
  deleteCommonTestApi,
  putQuestionUpdateApi,
  postTestQuestionApi,
  deleteTestQuestionApi,
  getCommonTestListApi,
  getCommonTestActivateApi
};
