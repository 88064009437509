import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_DASHBOARD_${type?.toUpperCase()}`,
  (type) => `SET_DASHBOARD_${type?.toUpperCase()}`,
  (type) => `POST_DASHBOARD_${type?.toUpperCase()}`,
  (type) => `PUT_DASHBOARD_${type?.toUpperCase()}`
);

const rank = properties('rank');
const scoreDetail = properties('scoreDetail');
const subscription = properties('subscription');
const latestTest = properties('latestTest');
const testHistory = properties('testHistory');
const studentStatus = properties('studentStatus');
const staffHistory = properties('staffHistory');
const userStatus = properties('userStatus');
const studentStatusExcel = properties('studentStatusExcel');
const staffStatusExcel = properties('staffStatusExcel');
const userStatusFilter = properties('userStatusFilter');
const navigationFilter = properties('navigationFilter');
const navigationCount = properties('navigationCount');

export {
  rank,
  scoreDetail,
  subscription,
  latestTest,
  testHistory,
  studentStatus,
  staffHistory,
  userStatus,
  studentStatusExcel,
  staffStatusExcel,
  userStatusFilter,
  navigationFilter,
  navigationCount
};
