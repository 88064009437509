import produce from 'immer';
import * as Types from './types';

const DEFAULT_STATE = {
  selectedQuestion: 0,
  lastQuestion: {
    class: -1,
    medium: -1,
    subject: -1,
    unit: -1,
    topic: -1,
    testType: -1,
    questionLevel: 1,
    questionType: 1,
    questionPattern: 1,
    questionCategory: 1
  },
  questionFilter: {
    page: 1,
    pageSize: 20,
    sort: '',
    filters: ''
  },
  searchFilter: {
    classId: -1,
    mediumId: -1,
    subjectId: -1,
    unitId: -1,
    questionStatus: -1,
    createdBy: -1,
    updatedBy: -1
  },
  count: 1,
  questions: [],
  selectedTab: 0
};

const questionData = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_UPLOAD_QUESTIONLIST:
      state.questions = action.payload;
      return state;
    case Types.SET_UPLOAD_QUESTIONDETAILLIST:
      state.questionsDetailList = action.payload;
      return state;
    case Types.SET_UPLOAD_QUESTIONFILTER:
      state.questionFilter = { ...state.questionFilter, ...action.payload };
      return state;
    case Types.SET_UPLOAD_SEARCHFILTER:
      state.searchFilter = { ...state.searchFilter, ...action.payload };
      return state;
    case Types.SET_UPLOAD_QUESTIONCOUNT:
      state.count = action.payload;
      return state;
    case Types.SET_UPLOAD_QUESTION:
      state.question = action.payload ?? {};
      return state;
    case Types.SET_UPLOAD_LASTQUESTION:
      state.lastQuestion = action.payload;
      return state;
    case Types.SET_UPLOAD_SELECTEDTAB:
      state.selectedTab = action.payload;
      return state;
    case Types.SET_UPLOAD_TRANSLATEQUESTION:
      state.translatedQuestion = action.payload;
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { questionData };
