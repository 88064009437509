import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_SUBSCRIPTION_${type?.toUpperCase()}`,
  (type) => `SET_SUBSCRIPTION_${type?.toUpperCase()}`,
  (type) => `POST_SUBSCRIPTION_${type?.toUpperCase()}`,
  (type) => `PUT_SUBSCRIPTION_${type?.toUpperCase()}`
);

const courseInfo = properties('courseInfo');
const singleCourseInfo = properties('singleCourseInfo');
const freeSubscriptionInfo = properties('freeSubscriptionInfo');
const purchaseCourseInfo = properties('purchaseCourseInfo');
const capturePaymentInfo = properties('capturePaymentInfo');
const subscribedInfo = properties('subscribedInfo');
const nonSubscribedInfo = properties('nonSubscribedInfo');

export {
  courseInfo,
  singleCourseInfo,
  freeSubscriptionInfo,
  purchaseCourseInfo,
  capturePaymentInfo,
  subscribedInfo,
  nonSubscribedInfo
};
