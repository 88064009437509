import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { LineChart, Line, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PREFIX = 'lineChartSmall';

const classes = {
  paperStyle: `${PREFIX}-paperStyle`,
  chartStyle: `${PREFIX}-chartStyle`,
  titleStyle: `${PREFIX}-titleStyle`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.paperStyle}`]: {
    height: 150,
    width: '100%'
  },

  [`& .${classes.chartStyle}`]: {
    height: 150,
    width: '100%'
  },

  [`& .${classes.titleStyle}`]: {
    padding: 5
  }
}));

function LineChartSmall(props) {
  const { title, chartData } = props;
  return (
    <StyledPaper className={classes.paperStyle}>
      <Typography variant="subtitle2" align="center" className={classes.titleStyle}>
        {title}
      </Typography>
      <div className={classes.chartStyle}>
        {/* <ResponsiveLine
          data={chartData}
          margin={{ top: 20, right: 30, bottom: 70, left: 30 }}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: "auto", max: "auto", stacked: true, reverse: false }}
          curve="natural"
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          enableGridX={false}
          enableGridY={false}
          colors={{ scheme: "accent" }}
          enablePoints={false}
          pointSize={8}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 5,
              translateY: 25,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        /> */}
        <ResponsiveContainer width="100%" height="75%">
          <LineChart data={chartData} margin={{ right: 20, left: 20 }}>
            <Tooltip />
            <Legend verticalAlign="bottom" />
            <Line type="monotone" dataKey="Navira" dot={false} stroke="#8884d8" strokeWidth={2} />
            <Line type="monotone" dataKey="Mine" dot={false} stroke="#888425" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </StyledPaper>
  );
}

export default memo(LineChartSmall);
