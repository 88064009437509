import { executeEpic } from '@helpers/utilities';
import { appStore } from 'app/redux/store';
import { stopLoading, showAlert } from 'app/redux/actions';
import {
  GET_SUBSCRIPTION_COURSEINFO,
  GET_SUBSCRIPTION_SINGLECOURSEINFO,
  POST_SUBSCRIPTION_FREESUBSCRIPTIONINFO,
  POST_SUBSCRIPTION_PURCHASECOURSEINFO,
  POST_SUBSCRIPTION_CAPTUREPAYMENTINFO,
  GET_SUBSCRIPTION_SUBSCRIBEDINFO,
  GET_SUBSCRIPTION_NONSUBSCRIBEDINFO
} from './types';
import {
  courseInfo,
  singleCourseInfo,
  freeSubscriptionInfo,
  purchaseCourseInfo,
  subscribedInfo,
  nonSubscribedInfo
} from './actions';
import {
  getCourses,
  getCourseById,
  postPurchaseFreeCourse,
  postPurchaseNewCourse,
  postCapturePayment,
  getSubscribedCoursesApi,
  getNonSubscribedCoursesApi
} from '../api';

const getCoursesEpic = (action$) =>
  executeEpic(GET_SUBSCRIPTION_COURSEINFO, getCourses, (responseData) => [
    courseInfo.set(responseData.response),
    stopLoading()
  ])(action$);

const getSubscribedCoursesEpic = (action$) =>
  executeEpic(GET_SUBSCRIPTION_SUBSCRIBEDINFO, getSubscribedCoursesApi, (responseData) => [
    subscribedInfo.set(responseData.response),
    stopLoading()
  ])(action$);

const getNonSubscribedCoursesEpic = (action$) =>
  executeEpic(GET_SUBSCRIPTION_NONSUBSCRIBEDINFO, getNonSubscribedCoursesApi, (responseData) => [
    nonSubscribedInfo.set(responseData.response),
    stopLoading()
  ])(action$);

const getCourseByIdEpic = (action$) =>
  executeEpic(GET_SUBSCRIPTION_SINGLECOURSEINFO, getCourseById, (responseData) => [
    singleCourseInfo.set(responseData.response)
  ])(action$);

const postFreeCourseEpic = (action$) =>
  executeEpic(POST_SUBSCRIPTION_FREESUBSCRIPTIONINFO, postPurchaseFreeCourse, (responseData) => {
    let actions = [
      showAlert('Course purchased', 'success'),
      freeSubscriptionInfo.set(responseData.response),
      nonSubscribedInfo.get('QueryType=3&filters=courseIsActive==true'),
      subscribedInfo.get('QueryType=3&filters=courseIsActive==true')
    ];
    const studentId = appStore?.getState()?.auth?.data?.StudentId;
    if (studentId && Number(studentId) > 0) {
      actions = [courseInfo.get(studentId), ...actions];
    }
    return actions;
  })(action$);

const postPurchaseNewCourseEpic = (action$) =>
  executeEpic(POST_SUBSCRIPTION_PURCHASECOURSEINFO, postPurchaseNewCourse, (responseData) => [
    purchaseCourseInfo.set(responseData.response)
  ])(action$);

const postCapturePaymentEpic = (action$) =>
  executeEpic(POST_SUBSCRIPTION_CAPTUREPAYMENTINFO, postCapturePayment, () => {
    const actions = [
      showAlert('Course purchased', 'success'),
      nonSubscribedInfo.get('QueryType=3&filters=courseIsActive==true'),
      subscribedInfo.get('QueryType=3&filters=courseIsActive==true')
    ];
    // const studentId = appStore?.getState()?.auth?.data?.StudentId;
    // if (studentId && Number(studentId) > 0) {
    //   actions = [courseInfo.get(studentId), ...actions];
    // }
    return actions;
  })(action$);

export default [
  getCoursesEpic,
  getCourseByIdEpic,
  postFreeCourseEpic,
  postPurchaseNewCourseEpic,
  postCapturePaymentEpic,
  getSubscribedCoursesEpic,
  getNonSubscribedCoursesEpic
];
