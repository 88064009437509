import { ajax } from 'rxjs/ajax';
import { BASE_URL } from 'views/common/apiConfig';
import { getToken } from '@helpers/utilities';

const host = `${BASE_URL}`;
const defaultHeader = {
  'Content-Type': 'application/json'
};

const callAjax = (options) => ajax(options);

const callApi = ({ url, method, headers, body, ...others }) => {
  const apiOptions = {
    url: `${host}${url}`,
    method,
    headers: headers || defaultHeader,
    body,
    ...others
  };

  return {
    anonymous: () => callAjax(apiOptions),
    withAuth: () => {
      apiOptions.headers = {
        ...apiOptions.headers,
        Authorization: `Bearer ${getToken()}`
      };
      return callAjax(apiOptions);
    }
  };
};

const callFormApi = (options) => {
  const { url: serviceUrl, method, ...other } = options;
  const url = `${host}${serviceUrl}`;
  const token = getToken();
  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  return {
    anonymous: () => callAjax({ url, method, ...other }),
    withAuth: () => callAjax({ url, method, headers, ...other })
  };
};

export { callApi, callFormApi };
