import { lazy } from 'react';

export const LandingPageConfig = {
  routes: [
    {
      path: '/',
      exact: true,
      component: lazy(() => import('./LandingPage'))
    },
    {
      path: '/material',
      exact: true,
      component: lazy(() => import('./MaterialTest'))
    }
  ]
};
