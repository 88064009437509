import { memo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const PREFIX = 'index';

const classes = {
  container: `${PREFIX}-container`,
  border: `${PREFIX}-border`,
  content: `${PREFIX}-content`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  [`& .${classes.border}`]: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },

  [`& .${classes.content}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    whiteSpace: 'nowrap'
  }
}));

const DividerWithText = ({ children }) => (
  <Root className={classes.container}>
    <div className={classes.border} />
    <Typography variant="h6" className={classes.content}>
      {children}
    </Typography>
    <div className={classes.border} />
  </Root>
);
export default memo(DividerWithText);

DividerWithText.propTypes = {
  children: PropTypes.element
};
