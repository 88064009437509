import { lazy } from 'react';

export const HistoryConfig = {
  routes: [
    {
      path: '/history',
      exact: true,
      component: lazy(() => import('./index'))
    },
    {
      path: '/analysis',
      exact: true,
      component: lazy(() => import('./sections/detailAnalysis'))
    },
    {
      path: '/analysis/examReview',
      exact: true,
      component: lazy(() => import('./sections/questionLayout'))
    },
    {
      path: '/history/all',
      exact: true,
      component: lazy(() => import('./sections/showAllDetails'))
    },
    {
      path: '/history/student/:studentId/:courseId',
      exact: true,
      component: lazy(() => import('./sections/detailHistoryTab'))
    }
  ]
};
