import { memo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  tabs: `${PREFIX}-tabs`,
  img: `${PREFIX}-img`,
  topRightFloat: `${PREFIX}-topRightFloat`,
  tabContent: `${PREFIX}-tabContent`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%'
  },

  [`& .${classes.tabs}`]: {
    background: theme.palette.background.paper
  },

  [`& .${classes.img}`]: {
    width: '100%',
    height: '100%'
  },

  [`& .${classes.topRightFloat}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '10px 20px'
  },

  [`& .${classes.tabContent}`]: {
    '& span': {
      display: 'flex',
      flexDirection: 'row'
    },
    minHeight: '50px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ marginTop: 20 }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

// const useTabStyles = makeStyles(({ theme }) => ({
//   [`& .${classes.root}`]: {
//     width: '100%'
//   },

//   [`& .${classes.tabs}`]: {
//     background: theme.palette.background.paper
//   },

//   [`& .${classes.img}`]: {
//     width: '100%',
//     height: '100%'
//   },

//   [`& .${classes.topRightFloat}`]: {
//     position: 'absolute',
//     top: 0,
//     right: 0,
//     padding: '10px 20px'
//   },

//   [`& .${classes.tabContent}`]: {
//     '& span': {
//       display: 'flex',
//       flexDirection: 'row'
//     },
//     minHeight: '50px'
//   }
// }));

function QuestionTab(props) {
  // const classes = useTabStyles();
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { source, value } = props;

  if (source?.length < 1) return null;

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    if (props?.tabChange) props.tabChange(newValue);
  };

  const handleChangeIndex = (index) => {
    // setValue(index);
    if (props?.tabChange) props.tabChange(index);
  };

  return (
    <Root>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
          // variant="fullWidth"
          aria-label="full width tabs example"
        >
          {source.map(({ label, icon }, key) => (
            <Tab
              sx={{ display: 'flex', flexDirection: 'row', '& img': { pr: 1 } }}
              key={key}
              label={!matches || icon === undefined ? label : ''}
              icon={icon}
              {...a11yProps(key)}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.views}
      >
        {source.map(({ content }, index) => (
          <TabPanel key={index} {...{ value, index, children: content, dir: theme.direction }} />
        ))}
      </SwipeableViews>
    </Root>
  );
}

export default memo(QuestionTab);
