export const LOADING = 'loading';
export const ERROR_RESPONSE = 'ERROR_RESPONSE';
export const SET_THEME = 'SET_THEME';
export const DEFAULT_THEME = 'DEFAULT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const RESET_STATE = 'RESET_STATE';
export const SHOW_N_HIDE_ALERT = 'SHOW_N_HIDE_ALERT';
export const SHOW_CUSTOM_DIALOG = 'SHOW_CUSTOME_DIALOG';
export const HIDE_CUSTOM_DIALOG = 'HIDE_CUSTOM_DIALOG';
export const IS_USER_AUTHORIZED = 'IS_USER_AUTHORIZED ';
export const IS_SESSION_IDLE = 'IS_SESSION_IDLE';
