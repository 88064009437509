import { memo } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';
import useStaffValidation from '@helpers/hooks/staffValidationHook';

const PREFIX = 'index';

const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  msg: `${PREFIX}-msg`,
  alert: `${PREFIX}-alert`,
  warningMsg: `${PREFIX}-warningMsg`,
  message: `${PREFIX}-message`,
  root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 100
  },

  [`& .${classes.icon}`]: {
    fontSize: 45,
    color: alpha(theme.palette.secondary.main, 70)
  },

  [`& .${classes.msg}`]: {
    marginTop: 5
  },

  [`& .${classes.alert}`]: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    '& div': {
      '& div + div': {
        width: '100%'
      }
    }
  },

  [`& .${classes.warningMsg}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.message}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  [`& .${classes.root}`]: {
    backgroundColor: '#FFA726'
  }
}));

// const defaultMsg = (
//   <Typography variant="subtitle1" color="textSecondary">
//     No items found !
//   </Typography>
// );

function NoResultFound() {
  const history = useHistory();
  const [isStaffValid] = useStaffValidation();
  const userType = useSelector((state) => state?.auth?.data?.UserType);
  const { pathname } = useSelector((state) => state?.router?.location);

  if (!isStaffValid && userType === 'Staff') {
    return (
      <Root style={{ paddingBottom: 8, paddingTop: 8 }}>
        <div>
          <Alert variant="filled" severity="warning">
            <AlertTitle>Profile update pending</AlertTitle>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={10} md={10}>
                <Typography>
                  {`Please fill in the required ${
                    userType === 'Student' ? 'academic details' : 'staff details'
                  } and verify your mobile number and
                  email address in personal details section to access this page.`}
                </Typography>
              </Grid>
              {pathname !== '/profile' ? (
                <Grid container justifyContent="flex-end" item xs={12} sm={2} md={2}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={(e) => {
                      history.push('/profile');
                      e.preventDefault();
                    }}
                  >
                    Update & Verify
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Alert>
        </div>
      </Root>
    );
  }
  return <></>;
}

export default memo(NoResultFound);
