import { memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// @mui/material components
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import MuiIconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import HeaderImage from 'assets/img/header.png';
import NavToolBar from './ToolBar';

const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  backgroundImage: `url(${HeaderImage})`,
  backgroundColor: '#4877fb',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',
  boxShadow: 'none',
  borderBottom: '0',
  marginBottom: '0',
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% -   ${theme.spacing(6.5)})`
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  paddingTop: '10px',
  zIndex: '1029',
  border: '0',
  padding: '10px 0',
  minHeight: '54px',
  display: 'block',
  ...(open && {
    backgroundImage: `url(${HeaderImage})`,
    backgroundColor: '#4877fb',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    marginLeft: 260,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${230}px)`
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    paddingTop: '10px',
    zIndex: '1029',
    border: '0',
    padding: '10px 0',
    minHeight: '54px',
    display: 'block'
  })
}));

const IconButton = styled(MuiIconButton)(({ open }) => ({
  marginRight: 2,
  ...(open && {
    display: 'none'
  })
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

function CustomHeader(props) {
  // const classes = useStyles();
  const { open, handleToggle, handleMobileToggle } = props;
  const { pathname } = useSelector((state) => state?.router?.location);
  const pageTitle = () => {
    // createTest, dashboard, events, exam, history, analysis, profile, subscription, upload;
    if (pathname.includes('createTest')) return 'Create Test';

    if (pathname.includes('dashboard')) return 'Dashboard';

    if (pathname.includes('events')) return 'Events';

    if (pathname.includes('exam')) return 'Exam';

    if (pathname.includes('history')) return 'History';

    if (pathname.includes('analysis')) return 'Analysis';

    if (pathname.includes('profile')) return 'Profile';

    if (pathname.includes('subscription')) return 'Subscription';

    if (pathname.includes('upload')) return 'Upload';

    if (pathname.includes('course')) return 'Course';

    if (pathname === '/userStatus') return 'User Status';

    if (pathname.includes('user')) return 'Bulk User';

    if (pathname.includes('commonTest')) return 'Common Test';

    if (pathname.includes('settings')) return 'Settings';
  };
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: open
        // })}
      >
        <Toolbar>
          <div style={{ flex: 1 }}>
            <Hidden mdDown implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleToggle}
                edge="start"
                open={open}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={handleToggle}
                edge="start"
                open={!open}
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography component="span" variant="h6">
                {pageTitle()}
              </Typography>
            </Hidden>
            <Hidden mdUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleMobileToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography component="span" variant="h6">
                {pageTitle()}
              </Typography>
            </Hidden>
          </div>
          <NavToolBar />
        </Toolbar>
      </AppBar>
    </>
  );
}

export default memo(CustomHeader);
