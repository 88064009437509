import { memo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AnswerOptions from '../AnswerSections';
import QuestionPattern from '../QuestionPattern';
import Alert from '../Alert';
import TextField from '../custom-text-field';
import KatexComponent from '../KatexComponent';

const PREFIX = 'index';

const classes = {
  questionTitle: `${PREFIX}-questionTitle`,
  questionDescription: `${PREFIX}-questionDescription`,
  answerDescription: `${PREFIX}-answerDescription`,
  checkBoxLayout: `${PREFIX}-checkBoxLayout`,
  categoryStyle: `${PREFIX}-categoryStyle`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.questionTitle}`]: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: '0px'
    }
  },

  [`& .${classes.questionDescription}`]: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px'
    },
    '& span': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
      fontWeight: '500 !important',
      fontSize: '1rem !important',
      lineHeight: '1.75 !important',
      letterSpacing: '0.00938em !important',
      color: `${theme.palette.text.primary} !important`
    }
  },

  [`& .${classes.answerDescription}`]: {
    margin: 16
  },

  [`& .${classes.checkBoxLayout}`]: {
    margin: 16
  },

  [`& .${classes.categoryStyle}`]: {
    '& legend': {
      marginLeft: '20px'
    },
    '& div': {
      display: 'block',
      marginLeft: '20px'
    }
  }
}));

const Image = styled('img')({
  width: '100%',
  height: '100%'
});

const ImageLayout = (props) =>
  props?.images?.map((image, index) => (
    <Stack key={index} spacing={1} sx={{ marginLeft: '24px', mt: '24px' }}>
      <Image src={image?.fullPath} alt={image.name} />
    </Stack>
  ));

function QuestionSection(props) {
  const {
    question,
    selectOption,
    onReviewClick,
    onPartBClick,
    isUpload,
    isReview,
    isTest,
    checked,
    isPartB,
    isCreateTest,
    onPrevious,
    onNext,
    onSubmit,
    createTestReview
  } = props;
  const [subjectiveValue, setSubjectiveValue] = useState(question?.answerValue);
  // useEffect(() => {
  //   if (question) setSubjectiveValue(question?.answerValue);
  // }, [question]);
  const [checkForReview, setCheckForReview] = useState(false);
  // const [isPartB, setIsPartB] = useState(false);
  const phoneRegex = /[^0-9.]/gi;
  const setSelected = (selectedOption) => {
    if (selectOption) selectOption(selectedOption);
  };
  const onCheckForReviewClick = (event) => {
    onReviewClick(event);
    setCheckForReview(!checkForReview);
  };
  const onPartBHandler = (event) => {
    onPartBClick(event);
  };
  const createMarkup = (htmlString) => ({ __html: htmlString });
  const processError = (errorHeader) => {
    const errorString = [];
    if (errorHeader) {
      const objectKeys = Object.keys(errorHeader);
      objectKeys?.map((objectKey) => errorString.push(`${objectKey} : ${errorHeader[objectKey]}`));
    }
    return errorString.length >= 1 ? (
      <Alert title="Error" alertType="error" messageArray={errorString} />
    ) : null;
  };
  // const processAnswer = () => {
  //   if (question?.answerOption === 0) {
  //     return (
  //       <Alert
  //         title="Not Attended"
  //         alertType="info"
  //         message={"You haven't answered this question."}
  //       />
  //     );
  //   }
  //   return question?.answer === question?.answerOption ? (
  //     <Alert title="Correct" alertType="success" message="Kudos! Your Answer is correct." />
  //   ) : (
  //     <Alert title="Wrong" alertType="error" message="Oops! Your answer is wrong." />
  //   );
  // };
  const processAnswer = () => {
    if (question?.answeredOptions?.length === 0) {
      return (
        <Alert
          title="Not Attended"
          alertType="info"
          message={"You haven't answered this question."}
        />
      );
    }
    if (question?.questionPattern === 6) {
      return question?.correctOptions?.length === question?.answeredOptions?.length &&
        question?.correctOptions.every((element) => question?.answeredOptions.includes(element)) ? (
        <Alert title="Correct" alertType="success" message="Kudos! Your Answer is correct." />
      ) : (
        <Alert title="Wrong" alertType="error" message="Oops! Your answer is wrong." />
      );
    }
    // Need to Check this below condition
    return question?.correctOptions.includes(question?.answeredOptions[0]) ? (
      <Alert title="Correct" alertType="success" message="Kudos! Your Answer is correct." />
    ) : (
      <Alert title="Wrong" alertType="error" message="Oops! Your answer is wrong." />
    );
  };
  const optionLetterCreation = (optionKey) => {
    switch (optionKey) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'D';
      default:
        return '-';
    }
  };

  const generateInputText = () => {
    if (isReview) return 'Your answer is';
    if (isUpload) return 'Answer is';
    return 'Enter your answer';
  };

  const onClearValue = () => {
    setSelected('');
  };

  const onSaveValue = () => {
    setSelected(subjectiveValue);
  };

  const optionSelected = (optionId) => question?.answeredOptions?.includes(optionId);
  // question.answer needs to be revisited
  const generateCorrectAnswer = () => {
    if (question?.questionPattern === 2) return `Correct Answer : ${question?.option1}`;
    if (question?.questionPattern === 6) {
      return `Correct Answer : ${question?.correctOptions
        ?.map((singleAnswer) =>
          optionLetterCreation(question?.answers.findIndex((answer) => answer?.id === singleAnswer))
        )
        .toString()}`;
    }
    return `Correct Answer : ${optionLetterCreation(
      question?.answers?.findIndex((answer) => answer?.id === question?.correctOptions?.[0])
    )}`;
  };

  const generateYourAnswer = () => {
    if (question?.questionPattern === 2) return `Your Answer : ${question?.answerValue}`;
    if (question?.questionPattern === 6) {
      return `Your Answer : ${question?.answeredOptions
        ?.map((singleAnswer) =>
          optionLetterCreation(question?.answers.findIndex((answer) => answer?.id === singleAnswer))
        )
        .toString()}`;
    }
    return `Your Answer : ${optionLetterCreation(
      question?.answers?.findIndex((answer) => answer?.id === question?.answeredOptions?.[0])
    )}`;
  };

  return (
    <StyledGrid container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      {isReview && processAnswer()}
      {isUpload && processError(question?.errors)}
      <Grid item xs={12}>
        <QuestionPattern
          questionPattern={
            question?.questionPattern === 3 || question?.primaryQId > 0
              ? 3
              : question?.questionPattern
          }
        />
      </Grid>
      {question?.primaryQId > 0 && (
        <Grid item xs={12}>
          <Typography
            className={classes.questionDescription}
            dangerouslySetInnerHTML={createMarkup(question?.primaryQuestionName)}
          />
        </Grid>
      )}
      {question?.images && <ImageLayout images={question?.images} />}
      {question?.name && (
        <Grid item xs={12}>
          <KatexComponent text={question?.name} />
        </Grid>
      )}
      {question?.questionPattern === 2 && (
        <>
          <Grid item container alignItems="center" spacing={2} style={{ padding: 16 }}>
            <Grid item>
              <Typography>{generateInputText()}</Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                value={subjectiveValue ?? ''}
                variant="outlined"
                disabled={!isTest}
                onChange={(e) => {
                  if (!props.isEmail) e.target.value = e.target.value.replace(phoneRegex, '');
                  setSubjectiveValue(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container>
            {isTest && (
              <>
                <Grid itemxs={6} sm={2} style={{ padding: 16 }}>
                  <Button variant="outlined" color="primary" size="small" onClick={onSaveValue}>
                    save
                  </Button>
                </Grid>
                <Grid item xs={6} sm={2} style={{ padding: 16 }}>
                  <Button variant="outlined" color="primary" size="small" onClick={onClearValue}>
                    clear
                  </Button>
                </Grid>

                <Grid item xs={10} sm={10} style={{ padding: 16 }}>
                  <Typography>* Click Save button to update your answer</Typography>
                  <Typography>* Click Clear button to reset your answer</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      {question?.questionPattern !== 3 &&
        question?.answers?.map((option, key) => (
          <Grid item xs={12} spacing={2} key={key}>
            <AnswerOptions
              uniqueKey={key}
              answer={option}
              isSelected={optionSelected(option?.id)}
              handleClick={setSelected}
            />
          </Grid>
        ))}
      {(isUpload || createTestReview) && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.questionDescription}>
              {generateCorrectAnswer()}
            </Typography>
          </Grid>
          <Typography variant="subtitle1">Answer Description</Typography>
          {question?.answers?.map((answer) => {
            if (answer.isCorrect) {
              return (
                <>
                  <ImageLayout images={answer?.descImages} />
                  <Grid item xs={12}>
                    <KatexComponent text={answer?.description} />
                  </Grid>
                </>
              );
            }
          })}
          {/* <Grid item xs={12}>
            {question?.isHtml ? (
              <Typography
                className={classes.questionDescription}
                dangerouslySetInnerHTML={createMarkup(question?.answerDescription)}
              />
            ) : (
              <Typography className={classes.questionDescription}>
                {question?.answerDescription}
              </Typography>
            )}
          </Grid> */}
        </>
      )}
      {isReview && (
        <>
          {!question?.isSubjective && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.questionDescription}>
                {generateYourAnswer()}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.questionDescription}>
              {generateCorrectAnswer()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Answer Description</Typography>
          </Grid>
          {question?.answers?.map((answer) => {
            if (answer.isCorrect) {
              return (
                <>
                  <ImageLayout images={answer?.descImages} />
                  <Grid item xs={12}>
                    <KatexComponent text={answer?.description} />
                  </Grid>
                </>
              );
            }
          })}
          {/* <Grid item xs={12}>
            {question?.isHtml ? (
              <Typography
                className={clx(classes.questionDescription, classes.answerDescription)}
                dangerouslySetInnerHTML={createMarkup(question?.answerDescription)}
              />
            ) : (
              <Typography className={clx(classes.questionDescription, classes.answerDescription)}>
                {question?.answerDescription}
              </Typography>
            )}
          </Grid> */}
        </>
      )}
      {isTest && (
        <Grid item className={classes.checkBoxLayout}>
          <FormControlLabel
            // className={classes.pl10}
            control={
              <Checkbox
                checked={checked}
                onChange={onCheckForReviewClick}
                name="checkedB"
                color="primary"
              />
            }
            label="Mark For Review"
          />
        </Grid>
      )}
      {isCreateTest && (
        <FormControlLabel
          disabled={question?.partNo > 0}
          control={
            <Checkbox checked={isPartB} onChange={onPartBHandler} name="isPartB" color="primary" />
          }
          label="Part B"
        />
      )}
      <Grid item container spacing={3} justifyContent="center" alignItems="center">
        <Grid item>
          <Button color="primary" variant="contained" size="sm" onClick={onPrevious}>
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" size="sm" onClick={onNext}>
            Next
          </Button>
        </Grid>
        {isTest || isUpload ? (
          <Grid item>
            <Button color="primary" variant="contained" size="sm" onClick={onSubmit}>
              Submit
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </StyledGrid>
  );
}

export default memo(QuestionSection);
