import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
// import reduxReset from 'redux-reset';
import { rootReducer } from './reducers';
import { rootEpic } from './epics';

export const history = createBrowserHistory();

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const epicMiddleware = createEpicMiddleware();
export const appStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware))
);
epicMiddleware.run(rootEpic);
