import { createSelector } from 'reselect';

const getProfile = (state) => state?.profile;

const getProfileInfo = createSelector(getProfile, (profile) => profile?.profileInfo);
const getUserInfo = createSelector(getProfile, (profile) => profile?.profileInfo?.user);
const getPersonalInfo = createSelector(getProfile, (profile) => profile?.profileInfo?.pesrsonal);
const getStudentInfo = createSelector(getProfile, (profile) => profile?.profileInfo?.student);
const getProfileOtpData = createSelector(getProfile, (profile) => profile?.profileOtp);
const getStaffInfo = createSelector(getProfile, (profile) => profile?.profileInfo?.staff);
const getOtpVerificationStauts = createSelector(getProfile, (profile) => profile?.isOtpVerified);

export {
  getProfileInfo,
  getUserInfo,
  getStudentInfo,
  getProfileOtpData,
  getStaffInfo,
  getOtpVerificationStauts,
  getPersonalInfo
};
