import produce from 'immer';
import * as Types from 'views/common/redux/types';

const masterInitialState = {};
const master = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_MASTER_STANDARD_DATA:
      state.standards = action.payload;
      return state;
    case Types.SET_MASTER_DEPARTMENT_DATA:
      state.departments = action.payload;
      return state;
    case Types.SET_MASTER_BOARD_DATA:
      state.boards = action.payload.filter((board) => board.name !== 'None');
      return state;
    case Types.SET_MASTER_MEDIUM_DATA:
      state.medium = action.payload;
      return state;
    case Types.SET_MASTER_STATE_DATA:
      state.states = action.payload;
      return state;
    case Types.SET_MASTER_DISTRICT_DATA:
      state.districts = action.payload;
      return state;
    case Types.SET_MASTER_ZIPCODE_DATA:
      state.zipCodes = action.payload;
      return state;
    case Types.SET_MASTER_EXAM_DATA:
      state.exams = action.payload;
      return state;
    case Types.SET_MASTER_TESTTYPE_DATA: {
      const typeObject = action.payload.filter((type) => type.testType !== 0);
      state.testTypes = typeObject;
      return state;
    }
    case Types.SET_MASTER_SUBJECT_DATA: {
      const uniqueObject = [
        ...new Map(action?.payload?.response?.map((item) => [item.subjectId, item])).values()
      ];
      if (!Array.isArray(action?.payload)) {
        if (action?.payload?.filter) {
          if (action?.payload?.filter === 1) {
            const filteredSubject = uniqueObject.filter((subject) => {
              if (subject.mediumName === 'English') {
                if (subject.subjectName !== 'Mathematics') return subject;
              } else if (subject.subjectName !== 'கணிதம்') return subject;
            });
            state.subjects = filteredSubject;
          } else if (action?.payload?.filter === 2) {
            const filteredSubject = uniqueObject.filter((subject) => {
              if (subject.mediumName === 'English') {
                if (
                  subject.subjectName === 'Physics' ||
                  subject.subjectName === 'Chemistry' ||
                  subject.subjectName === 'Mathematics'
                )
                  return subject;
              } else if (
                subject.subjectName === 'இயற்பியல்' ||
                subject.subjectName === 'வேதியியல்' ||
                subject.subjectName === 'கணிதம்'
              )
                return subject;
            });
            state.subjects = filteredSubject;
          } else if (action?.payload?.filter === 3) {
            state.subjects = uniqueObject;
          }
        } else {
          state.subjects = uniqueObject;
        }
      } else {
        state.subjects = uniqueObject;
      }
      return state;
    }

    case Types.SET_MASTER_UNIT_DATA:
      state.units = action.payload;
      return state;
    case Types.SET_MASTER_TOPIC_DATA:
      state.topics = action.payload;
      return state;
    case Types.SET_MASTER_LEVEL_DATA: {
      const levelObject = [...action.payload];
      const levelIndex = levelObject.findIndex((level) => level.questionLevel === 0);
      levelObject[levelIndex] = { ...levelObject[levelIndex], name: 'All' };
      state.levels = levelObject;
      return state;
    }
    case Types.SET_MASTER_TESTSTATUS_DATA: {
      const statusObject = action.payload.filter((status) => status.status !== 0);
      state.testStatuses = statusObject;
      return state;
    }
    case Types.SET_MASTER_SCHOOLTYPE_DATA:
      state.schoolTypes = action.payload;
      return state;
    case Types.SET_MASTER_EXAMLIST_DATA:
      state.examList = action.payload;
      return state;
    case Types.SET_MASTER_COURSELIST_DATA:
      state.courseList = action.payload;
      return state;
    case Types.SET_MASTER_CLIENTLIST_DATA:
      state.clientList = action.payload;
      return state;
    case Types.SET_MASTER_COMMONTESTLIST_DATA:
      state.commonTestList = action.payload;
      return state;
    case Types.SET_MASTER_SUBJECTLIST_DATA:
      state.subjectList = action.payload;
      return state;
    case Types.SET_MASTER_EXAMRULE_DATA:
      state.examRule = action.payload;
      return state;
    case Types.SET_MASTER_PERSONLIST_DATA:
      state.personList = action.payload;
      return state;
    case Types.SET_MASTER_SUBJECTDETAIL_DATA:
      state.subjectDetail = action.payload;
      return state;
    case Types.SET_MASTER_QUESTIONCOUNT_DATA:
      state.questionCount = action.payload;
      return state;
    case Types.SET_MASTER_QUESTIONTYPE_DATA:
      state.questionType = action.payload;
      return state;
    case Types.SET_MASTER_QUESTIONPATTERN_DATA: {
      const patternObject = action.payload.filter((pattern) => pattern.questionPattern !== 0);
      state.questionPattern = patternObject;
      return state;
    }
    case Types.SET_MASTER_CLPT_DATA:
      state.clpt = action.payload;
      return state;
    default:
      return state;
  }
}, masterInitialState);

export { master };
