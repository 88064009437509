// const START_EXAM = "START_EXAM";
// const QUESTION_UPDATE = "QUESTION_UPDATE";
// const ANSWER_SELECTED = "ANSWER_SELECTED";
// const SELECTED_QUESTION = "SELECTED_QUESTION";
// const CHECK_FOR_REVIEW = "CHECK_FOR_REVIEW";
// const UPDATE_TIME = "UPDATE_TIME";

// export {
//   START_EXAM,
//   QUESTION_UPDATE,
//   ANSWER_SELECTED,
//   SELECTED_QUESTION,
//   CHECK_FOR_REVIEW,
//   UPDATE_TIME,
// };

export const GET_PRACTICE_TEST = 'GET_PRACTICE_TEST';
export const POST_PRACTICE_TEST = 'POST_PRACTICE_TEST';
export const SET_PRACTICE_TEST = 'SET_PRACTICE_TEST';

export const GET_SELECTEDQUESTION_TEST = 'GET_SELECTEDQUESTION_TEST';
export const SET_SELECTEDQUESTION_TEST = 'SET_SELECTEDQUESTION_TEST';

export const GET_CHECKFORREVIEW_TEST = 'GET_CHECKFORREVIEW_TEST';
export const SET_CHECKFORREVIEW_TEST = 'SET_CHECKFORREVIEW_TEST';

export const GET_ANSWERSELECTED_TEST = 'GET_ANSWERSELECTED_TEST';
export const SET_ANSWERSELECTED_TEST = 'SET_ANSWERSELECTED_TEST';

export const GET_SUBJECTIVEANSWER_TEST = 'GET_SUBJECTIVEANSWER_TEST';
export const SET_SUBJECTIVEANSWER_TEST = 'SET_SUBJECTIVEANSWER_TEST';

export const GET_MULTICORRECT_TEST = 'GET_MULTICORRECT_TEST';
export const SET_MULTICORRECT_TEST = 'SET_MULTICORRECT_TEST';

export const GET_TIMER_TEST = 'GET_TIMER_TEST';
export const SET_TIMER_TEST = 'SET_TIMER_TEST';
export const PUT_TIMER_TEST = 'PUT_TIMER_TEST';

export const POST_SAVE_TEST = 'POST_SAVE_TEST';

export const GET_FINISH_TEST = 'GET_FINISH_TEST';
export const SET_FINISH_TEST = 'SET_FINISH_TEST';

export const GET_COMMON_TEST = 'GET_COMMON_TEST';
export const SET_COMMON_TEST = 'SET_COMMON_TEST';

export const GET_RESUME_TEST = 'GET_RESUME_TEST';
export const SET_RESUME_TEST = 'SET_RESUME_TEST';

export const GET_PING_TEST = 'GET_PING_TEST';
