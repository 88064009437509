import produce from 'immer';
import * as Types from './types';

const DEFAULT_STATE = {
  selectedQuestion: 0
};

const uploadData = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_UPLOAD_TESTDOCUMENT:
      state.questions = action.payload;
      state.selectedQuestion = 0;
      return state;
    case Types.SET_UPLOAD_CONFIRMUPLOAD:
      state.isQuestionUploadSuccess = true;
      return state;
    case Types.SET_UPLOAD_CURRENTQUESTION:
      state.selectedQuestion = action.payload;
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { uploadData };
