import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_COMMON`,
  (type) => `SET_${type?.toUpperCase()}_COMMON`,
  (type) => `POST_${type?.toUpperCase()}_COMMON`,
  (type) => `PUT_${type?.toUpperCase()}_COMMON`
);

const testInfo = properties('testInfo');
const navigationFilter = properties('navigationFilter');
const testDetailInfo = properties('testDetailInfo');
const studentCount = properties('studentCount');
const course = properties('course');
const student = properties('student');
const reassign = properties('reassign');
const activate = properties('activate');

export {
  testInfo,
  navigationFilter,
  testDetailInfo,
  course,
  student,
  reassign,
  activate,
  studentCount
};
