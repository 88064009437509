import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_USER_${type?.toUpperCase()}`,
  (type) => `SET_USER_${type?.toUpperCase()}`,
  (type) => `POST_USER_${type?.toUpperCase()}`,
  (type) => `PUT_USER_${type?.toUpperCase()}`
);

const availabilityPhone = properties('availabilityPhone');
const availabilityEmail = properties('availabilityEmail');
const client = properties('client');
const register = properties('register');
const otp = properties('otp');
const registerComplete = properties('registerComplete');
const login = properties('login');
const logout = properties('logout');
const refreshToken = properties('refreshToken');
const forgetPasswordInitiate = properties('forgetPasswordInitiate');
const forgetPasswordComplete = properties('forgetPasswordComplete');
const fpActiveStep = properties('fpActiveStep');
const forgetPasswordOtp = properties('forgetPasswordOtp');
const testOtp = properties('testOtp');
const resendOtp = properties('resendOtp');

export {
  availabilityPhone,
  availabilityEmail,
  client,
  register,
  otp,
  registerComplete,
  login,
  logout,
  refreshToken,
  forgetPasswordInitiate,
  forgetPasswordComplete,
  fpActiveStep,
  forgetPasswordOtp,
  testOtp,
  resendOtp
};
