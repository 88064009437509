export const POST_CREATETEST_COMMONTEST_DATA = 'POST_CREATETEST_COMMONTEST_DATA';
export const SET_CREATETEST_COMMONTEST_DATA = 'SET_CREATETEST_COMMONTEST_DATA';

export const GET_CREATETEST_COMMONTEST_DATA = 'GET_CREATETEST_COMMONTEST_DATA';
export const PUT_CREATETEST_COMMONTEST_DATA = 'PUT_CREATETEST_COMMONTEST_DATA';
export const DELETE_CREATETEST_COMMONTEST_DATA = 'DELETE_CREATETEST_COMMONTEST_DATA';

export const GET_CREATETEST_DOCUMENT_DATA = 'GET_CREATETEST_DOCUMENT_DATA';
export const SET_CREATETEST_DOCUMENT_DATA = 'SET_CREATETEST_DOCUMENT_DATA';

export const GET_CREATETEST_DOCUMENTCOUNT_DATA = 'GET_CREATETEST_DOCUMENTCOUNT_DATA';
export const SET_CREATETEST_DOCUMENTCOUNT_DATA = 'SET_CREATETEST_DOCUMENTCOUNT_DATA';

export const GET_CREATETEST_QUESTION_DATA = 'GET_CREATETEST_QUESTION_DATA';
export const SET_CREATETEST_QUESTION_DATA = 'SET_CREATETEST_QUESTION_DATA';

export const GET_CREATETEST_QUESTIONCOUNT_DATA = 'GET_CREATETEST_QUESTIONCOUNT_DATA';
export const SET_CREATETEST_QUESTIONCOUNT_DATA = 'SET_CREATETEST_QUESTIONCOUNT_DATA';

export const GET_CREATETEST_COURSE_DATA = 'GET_CREATETEST_COURSE_DATA';
export const SET_CREATETEST_COURSE_DATA = 'SET_CREATETEST_COURSE_DATA';

export const GET_CREATETEST_SUBJECT_DATA = 'GET_CREATETEST_SUBJECT_DATA';
export const SET_CREATETEST_SUBJECT_DATA = 'SET_CREATETEST_SUBJECT_DATA';

export const GET_CREATETEST_UNIT_DATA = 'GET_CREATETEST_UNIT_DATA';
export const SET_CREATETEST_UNIT_DATA = 'SET_CREATETEST_UNIT_DATA';

export const GET_CREATETEST_QUESTIONFILTER_DATA = 'GET_CREATETEST_QUESTIONFILTER_DATA';
export const SET_CREATETEST_QUESTIONFILTER_DATA = 'SET_CREATETEST_QUESTIONFILTER_DATA';

export const GET_CREATETEST_DOCUMENTFILTER_DATA = 'GET_CREATETEST_DOCUMENTFILTER_DATA';
export const SET_CREATETEST_DOCUMENTFILTER_DATA = 'SET_CREATETEST_DOCUMENTFILTER_DATA';

export const SET_CREATETEST_SELECTEDQUESTIONS_DATA = 'SET_CREATETEST_SELECTEDQUESTIONS_DATA';

export const SET_CREATETEST_REMOVEQUESTIONS_DATA = 'SET_CREATETEST_REMOVEQUESTIONS_DATA';

export const SET_CREATETEST_CLEARQUESTIONS_DATA = 'SET_CREATETEST_CLEARQUESTIONS_DATA';

export const PUT_CREATETEST_QUESTIONUPDATE_DATA = 'PUT_CREATETEST_QUESTIONUPDATE_DATA';

export const GET_CREATETEST_SELECTEDQUESTION_DATA = 'GET_CREATETEST_SELECTEDQUESTION_DATA';
export const SET_CREATETEST_SELECTEDQUESTION_DATA = 'SET_CREATETEST_SELECTEDQUESTION_DATA';

export const SET_CREATETEST_SECTIONUPDATE_DATA = 'SET_CREATETEST_SECTIONUPDATE_DATA';

export const GET_CREATETEST_QUESTIONLIST_DATA = 'GET_CREATETEST_QUESTIONLIST_DATA';
export const SET_CREATETEST_QUESTIONLIST_DATA = 'SET_CREATETEST_QUESTIONLIST_DATA';

export const GET_CREATETEST_NEWEXAM_DATA = 'GET_CREATETEST_NEWEXAM_DATA';
export const SET_CREATETEST_NEWEXAM_DATA = 'SET_CREATETEST_NEWEXAM_DATA';

export const POST_CREATETEST_TESTQUESTION_DATA = 'POST_CREATETEST_TESTQUESTION_DATA';
export const DELETE_CREATETEST_TESTQUESTION_DATA = 'DELETE_CREATETEST_TESTQUESTION_DATA';
export const SET_CREATETEST_TESTQUESTION_DATA = 'SET_CREATETEST_TESTQUESTION_DATA';

export const GET_CREATETEST_COMMONTESTREMOVE_DATA = 'GET_CREATETEST_COMMONTESTREMOVE_DATA';

export const GET_CREATETEST_COMMONTESTFILTER_DATA = 'GET_CREATETEST_COMMONTESTFILTER_DATA';
export const SET_CREATETEST_COMMONTESTFILTER_DATA = 'SET_CREATETEST_COMMONTESTFILTER_DATA';

export const GET_CREATETEST_TESTFILTERS_DATA = 'GET_CREATETEST_TESTFILTERS_DATA';
export const SET_CREATETEST_TESTFILTERS_DATA = 'SET_CREATETEST_TESTFILTERS_DATA';

export const GET_CREATETEST_COMMONTESTLIST_DATA = 'GET_CREATETEST_COMMONTESTLIST_DATA';
export const SET_CREATETEST_COMMONTESTLIST_DATA = 'SET_CREATETEST_COMMONTESTLIST_DATA';

export const GET_CREATETEST_COMMONTESTCOUNT_DATA = 'GET_CREATETEST_COMMONTESTCOUNT_DATA';
export const SET_CREATETEST_COMMONTESTCOUNT_DATA = 'SET_CREATETEST_COMMONTESTCOUNT_DATA';

export const GET_CREATETEST_COMMONTESTACTIVATE_DATA = 'GET_CREATETEST_COMMONTESTACTIVATE_DATA';

export const GET_CREATETEST_FINDQUESTION_DATA = 'GET_CREATETEST_FINDQUESTION_DATA';
export const SET_CREATETEST_FINDQUESTION_DATA = 'SET_CREATETEST_FINDQUESTION_DATA';
