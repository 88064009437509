import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_BULKIMPORT_${type?.toUpperCase()}`,
  (type) => `SET_BULKIMPORT_${type?.toUpperCase()}`,
  (type) => `POST_BULKIMPORT_${type?.toUpperCase()}`,
  (type) => `PUT_BULKIMPORT_${type?.toUpperCase()}`
);

const client = properties('client');
const studentUpload = properties('studentUpload');
const studentDownloadExcel = properties('studentDownloadExcel');
const staffUpload = properties('staffUpload');
const staffDownloadExcel = properties('staffDownloadExcel');

export { client, studentDownloadExcel, studentUpload, staffUpload, staffDownloadExcel };
