export const errorMapper = (error) => {
  if (error) {
    const errors = error?.errors;
    if (errors?.length > 0) {
      const { value } = errors[0];
      return `${value[0]}`;
    }

    if (error.errorMessage) {
      return error.errorMessage;
    }
  }
};
