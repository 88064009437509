import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Timer from './timer';

const PREFIX = 'index';

const classes = {
  questionTitle: `${PREFIX}-questionTitle`,
  layoutPadding: `${PREFIX}-layoutPadding`,
  timierIcon: `${PREFIX}-timierIcon`,
  timierText: `${PREFIX}-timierText`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.questionTitle}`]: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: '0px'
    }
  },

  [`& .${classes.layoutPadding}`]: {
    padding: 8
  },

  [`& .${classes.timierIcon}`]: {
    margin: 3
  },

  [`& .${classes.timierText}`]: {
    margin: '15px 15px 15px 15px'
  }
}));

function QuestionHeader(props) {
  const { questionNumber, totalCount, timer, duration } = props;
  return (
    <StyledGrid container>
      <Grid item xs={6} sm={9}>
        <Typography variant="h6" color="primary" className={classes.questionTitle}>
          {`Question ${questionNumber} of ${totalCount}`}
        </Typography>
      </Grid>
      {timer && (
        <Grid item xs={6} sm={3} className={classes.layoutPadding}>
          <Timer totalDuration={duration} timerSeconds={timer ?? {}} />
        </Grid>
      )}
    </StyledGrid>
  );
}

export default memo(QuestionHeader);
