import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as CommonSelectors from 'app/redux/selectors';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyle from '@helpers/components/NaviraTheme/themes/globalStyles';
import { BaseOptionChartStyle } from '@helpers/components/Chart/ChartBaseOptions';
import shape from './themes/shape';
import palette from './themes/palette';
import typography from './themes/typography';
import componentsOverride from './themes/overrides';
import shadows, { customShadows } from './themes/shadows';
import typoTheme from './themes/typoTheme';

function NaviraTheme(props) {
  // const defaultTheme = useSelector(CommonSelectors.getDefaultTheme);
  // const darkTheme = useSelector(CommonSelectors.getDarkTheme);
  const isDarkTheme = useSelector(CommonSelectors.getThemeType);
  // const themeObject = isDarkTheme ? darkTheme : defaultTheme;

  const themeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows
    }),
    []
  );

  const themeObject = isDarkTheme
    ? { palette: { mode: 'dark' }, typography: typoTheme }
    : themeOptions;

  const theme = createTheme({
    ...themeObject
  });
  theme.components = componentsOverride(theme);
  console.log(theme);
  return (
    // <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BaseOptionChartStyle />
      <CssBaseline />
      {props.children}
    </ThemeProvider>
    // </StyledEngineProvider>
  );
}

export default memo(NaviraTheme);
