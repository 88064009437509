import produce from 'immer';
import * as Types from './types';

const DEFAULT_STATE = {
  selectedQuestion: 0,
  timer: {
    time: {},
    seconds: 300
  }
};

const examData = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_PRACTICE_TEST: {
      const { testQus, ...others } = action.payload;
      const questionList = testQus
        .sort((a, b) => a.orderNo - b.orderNo)
        .map((question, index) => ({ ...question, questionKey: index }));
      state.questions = { ...others, testQus: questionList };
      state.timer.seconds = state.questions.test.remainingDuration ?? 20 * 60;
      state.totalDuration = state.questions.test.duration * 60;
      state.selectedQuestion = 0;
      return state;
    }
    case Types.SET_SELECTEDQUESTION_TEST:
      state.selectedQuestion = action.payload;
      return state;
    case Types.SET_CHECKFORREVIEW_TEST:
      state.questions.testQus[action.payload.questionId].isCheckForReview = action.payload.checked;
      return state;
    case Types.SET_ANSWERSELECTED_TEST:
      // if (
      //   state.questions.testQus[action.payload.questionIndex].answerOptions ===
      //   action.payload.answerOptions
      // ) {
      //   state.questions.testQus[action.payload.questionIndex].answerOptions = 0;
      // } else {
      //   state.questions.testQus[action.payload.questionIndex].answerOption =
      //     action.payload.answerOption;
      // }
      if (
        state.questions.testQus[action.payload.questionIndex]?.answeredOptions?.includes(
          action.payload.answerOptions
        )
      ) {
        state.questions.testQus[action.payload.questionIndex].answeredOptions = [];
      } else {
        state.questions.testQus[action.payload.questionIndex].answeredOptions = [
          action.payload.answerOptions
        ];
      }
      return state;
    case Types.SET_SUBJECTIVEANSWER_TEST:
      state.questions.testQus[action.payload.questionIndex].answerValue =
        action.payload.answerValue;
      return state;
    case Types.SET_MULTICORRECT_TEST:
      if (
        state.questions.testQus[action.payload.questionIndex].answeredOptions?.includes(
          action.payload.answerOptions
        )
      ) {
        state.questions.testQus[action.payload.questionIndex].answeredOptions =
          state.questions.testQus[action.payload.questionIndex].answeredOptions?.filter(
            (answer) => answer !== action.payload.answerOptions
          );
      } else {
        state.questions.testQus[action.payload.questionIndex].answeredOptions = [
          ...state.questions.testQus[action.payload.questionIndex].answeredOptions,
          action.payload.answerOptions
        ];
      }
      return state;
    case Types.SET_TIMER_TEST: {
      const { newTime, newSeconds } = action.payload;
      state.timer.time = newTime;
      state.timer.seconds = newSeconds || state.timer.seconds;
      return state;
    }
    case Types.PUT_TIMER_TEST:
      state.timer.seconds = action.payload;
      return state;
    case Types.SET_FINISH_TEST:
      state.finalResult = action.payload;
      return state;
    case Types.SET_COMMON_TEST:
      state.commonTestList = action.payload;
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { examData };
