import produce from 'immer';
import * as Types from './types';

const profileInitialState = {};
const profile = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_PROFILE_PROFILEINFO:
      state.profileInfo = action.payload;
      return state;
    case Types.SET_PROFILE_USERINFO:
      state.profileInfo.user = action.payload;
      return state;

    case Types.SET_PROFILE_PERSONALINFO:
      state.profileInfo.personal = action.payload;
      return state;

    case Types.SET_PROFILE_STUDENTINFO:
      state.profileInfo.student = action.payload;
      return state;

    case Types.SET_PROFILE_CONTACTVERIFY:
      state.profileOtp = action.payload;
      return state;

    case Types.SET_PROFILE_CONFIRMCONTACTCHANGE:
      state.isOtpVerified = action.payload;
      return state;

    case Types.SET_PROFILE_REQUESTCONTACTCHANGE:
      state.profileOtp = action.payload;
      return state;

    case Types.SET_PROFILE_STAFFINFO:
      state.profileInfo.staff = action.payload;
      return state;
    default:
      return state;
  }
}, profileInitialState);

export { profile };
