import { callApi, callFormApi } from '@helpers/utilities';

const callerFormAuth = (method, url, body) => callFormApi({ method, url, body }).withAuth();

export const getQuestion = (questionId) =>
  callApi({
    method: 'GET',
    url: `/questions/${questionId}`
  }).withAuth();
export const getQuestions = (questionParam) =>
  callApi({
    method: 'GET',
    url: `/questions?${questionParam}`
  }).withAuth();
export const postQuestion = (body) =>
  callApi({ method: 'POST', url: `/questions`, body }).withAuth();
export const putQuestion = (body) => callApi({ method: 'PUT', url: `/questions`, body }).withAuth();
export const getApproveQuestion = (questionId) =>
  callApi({
    method: 'GET',
    url: `/questions/${questionId}/approve`
  }).withAuth();
export const postRetunQuestion = (returnParam) =>
  callApi({
    method: 'POST',
    url: `/questions/${returnParam?.questionId}/reject`,
    body: returnParam.body
  }).withAuth();

export const postQuestionImageApi = (uploadRequestData) =>
  callerFormAuth(
    'POST',
    `/questions/${uploadRequestData?.questionId}/image`,
    uploadRequestData?.formParams
  );

export const postAnswerImageApi = (uploadRequestData) =>
  callerFormAuth(
    'POST',
    `/questions/${uploadRequestData?.questionId}/answers/${uploadRequestData?.answerId}/image?isDesc=${uploadRequestData?.isDesc}`,
    uploadRequestData?.formParams
  );

export const deleteQuestionApi = (questionId) =>
  callApi({
    method: 'DELETE',
    url: `/questions/${questionId}`
  }).withAuth();

export const deleteQuestionImageApi = (requestData) =>
  callApi({
    method: 'DELETE',
    url: `/questions/${requestData?.questionId}/image/${requestData?.imageId}`
  }).withAuth();

export const deleteAnswerImageApi = (requestData) =>
  callApi({
    method: 'DELETE',
    url: `/questions/${requestData?.questionId}/answers/${requestData?.answerId}/image/${requestData?.imageId}?isDesc=${requestData?.isDesc}`
  }).withAuth();

export const getTranslateQuestionApi = (requestData) =>
  callApi({
    method: 'GET',
    url: `/questions/${requestData?.questionId}/translate/${requestData?.language}`
  }).withAuth();
