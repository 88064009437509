import { useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Hidden from '@mui/material/Hidden';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoreIcon from '@mui/icons-material/MoreVert';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Logout from '@mui/icons-material/PowerSettingsNew';
import * as CommonActions from 'app/redux/actions';
import * as CommonSelectors from 'app/redux/selectors';
import SessionUpdate from 'components/SessionUpdate';
import * as Actions from 'views/common/redux/actions';
import { logout } from 'views/user/redux/actions';
import NaviraLogo from 'assets/img/navira_symbol.png';

const PREFIX = 'ToolBar';

const classes = {
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  sectionMobile: `${PREFIX}-sectionMobile`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.sectionDesktop}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },

  [`& .${classes.sectionMobile}`]: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const USER_MENU = [
  { name: 'Profile', path: '/profile' },
  { name: 'My Account' },
  { name: 'Log out', path: '/login?' }
];

function ToolBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isDarkTheme = useSelector(CommonSelectors.getThemeType);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const userType = useSelector((state) => state?.auth?.data?.UserType ?? undefined);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const navigateByMenu = (menu) => {
    if (menu?.name?.toLowerCase() === 'log out') {
      dispatch(CommonActions.startLoading());
      dispatch(logout.get());
      return;
    }
    if (menu?.path) {
      history.push(menu.path);
    }
  };

  const handleMenuClose = (menu) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigateByMenu(menu);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleThemeClick = () => {
    dispatch(CommonActions.setTheme(!isDarkTheme));
  };

  const onClickLogout = () => {
    dispatch(Actions.flag.set(true));
  };

  const onClickProfile = () => {
    history.push('/profile');
  };

  const onClickSubscription = () => {
    history.push('/subscription');
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      edge="end"
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {USER_MENU?.map((menu) => (
        <MenuItem key={menu.name} onClick={() => handleMenuClose(menu)}>
          {menu.name}
        </MenuItem>
      ))}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      edge="end"
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          onClick={handleThemeClick}
          size="large"
        >
          {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <Typography align="center">Theme</Typography>
      </MenuItem>
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge variant="dot" color="secondary">
            <ChatIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge variant="dot" color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      {(userType === 'Student' || userType === 'Staff') && (
        <Root>
          <MenuItem onClick={onClickSubscription}>
            <IconButton aria-label="Show subscription" color="inherit" size="large">
              <CreditCardIcon />
            </IconButton>
            <Typography align="center">Course</Typography>
          </MenuItem>
          <MenuItem onClick={onClickProfile}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
            <Typography align="center">Profile</Typography>
          </MenuItem>
        </Root>
      )}
      <MenuItem onClick={onClickLogout}>
        <IconButton aria-label="show 4 new mails" color="inherit" size="large">
          <Logout />
        </IconButton>
        <Typography align="center">Logout</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <SessionUpdate />
      <div className={classes.sectionDesktop}>
        <Hidden xsUp>
          <img src={NaviraLogo} alt="hiddenLogo" />
        </Hidden>
        <IconButton color="inherit" onClick={handleThemeClick} size="large">
          {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        {/* <IconButton edge="end" aria-label="show 4 new mails" color="inherit">
        <Badge badgeContent={4} color="secondary" variant="dot">
          <ChatIcon />
        </Badge>
      </IconButton> */}
        {/* <IconButton endge="end" aria-label="show 17 new notifications" color="inherit">
        <Badge variant="dot" color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton> */}
        {(userType === 'Student' || userType === 'Staff') && (
          <>
            <IconButton
              aria-label="show Subscription"
              color="inherit"
              onClick={onClickSubscription}
              size="large"
            >
              <CreditCardIcon />
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={onClickProfile}
              size="large"
            >
              <AccountCircle />
            </IconButton>
          </>
        )}
        <IconButton aria-label="Logout Button" color="inherit" onClick={onClickLogout} size="large">
          <Logout />
        </IconButton>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
          edge="end"
          size="large"
        >
          <MoreIcon />
        </IconButton>
      </div>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}

export default memo(ToolBar);
