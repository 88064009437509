import * as Types from 'views/common/redux/types';
import { PropMapper } from '@helpers/utilities';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_FOR_SECURITY`,
  (type) => `SET_${type?.toUpperCase()}_FOR_SECURITY`
);

const refreshToken = properties('refreshToken');

export { refreshToken };
