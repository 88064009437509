import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const PREFIX = 'GridItem';

const classes = {
  grid: `${PREFIX}-grid`
};

const StyledGrid = styled(Grid)({
  [`& .${classes.grid}`]: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    flexBasis: 'auto'
  }
});

function GridItem(props) {
  const { children, className, ...rest } = props;
  return (
    <StyledGrid item {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </StyledGrid>
  );
}

export default memo(GridItem);
