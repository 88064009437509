import { lazy } from 'react';

export const SubscriptionConfig = {
  routes: [
    {
      path: '/subscription',
      exact: true,
      component: lazy(() => import('./index'))
    },
    {
      path: '/subscription/package/:id/:purchased',
      exact: true,
      component: lazy(() => import('./sections/packageDetail'))
    }
  ]
};
