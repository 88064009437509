import * as Types from 'views/common/redux/types';
import { PropMapper } from '@helpers/utilities';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_DATA`,
  (type) => `SET_${type?.toUpperCase()}_DATA`
);

const auth = properties('auth');
const authStudentId = properties('authStudentId');
const authStaffId = properties('authStaffId');

export { auth, authStudentId, authStaffId };
