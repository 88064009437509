import produce from 'immer';
import * as Types from './types';

const subscriptionInitialState = {};
const subscription = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_SUBSCRIPTION_COURSEINFO:
      state.courses = action.payload;
      return state;
    case Types.SET_SUBSCRIPTION_SUBSCRIBEDINFO:
      state.subscribedCourses = action.payload;
      return state;
    case Types.SET_SUBSCRIPTION_NONSUBSCRIBEDINFO:
      state.nonSubscribedCourses = action.payload;
      return state;
    case Types.SET_SUBSCRIPTION_SINGLECOURSEINFO:
      state.courseDetail = action.payload;
      return state;
    case Types.SET_SUBSCRIPTION_FREESUBSCRIPTIONINFO:
      state.freeSubscription = action.payload;
      return state;
    case Types.SET_SUBSCRIPTION_PURCHASECOURSEINFO:
      state.recentPurchase = action.payload;
      return state;
    case Types.SET_SUBSCRIPTION_CAPTUREPAYMENTINFO:
      state.paymentConfirmation = action.payload;
      return state;

    default:
      return state;
  }
}, subscriptionInitialState);

export { subscription };
