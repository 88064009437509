import katex from 'katex';
import 'katex/dist/contrib/mhchem';
// eslint-disable-next-line import/extensions
import 'katex/dist/contrib/auto-render.min.js';
import { Box, Typography } from '@mui/material';
import './katexsample.css';

const KatexComponent = (props) => {
  const createMarkup = (formulaString) => {
    try {
      return {
        __html: katex.renderToString(String.raw`${formulaString}`, {
          throwOnError: false,
          displayMode: true,
          macros: {
            '\\power': '\\\\{#1^#2}',
            '\\integral': '{\\int^{{#1}}_#2}',
            '\\sqpower': '\\sqrt[#1]{#2}',
            '\\sq': '\\sqrt{#1}',
            '\\xhooklarrow': '\\xhookleftarrow{#1}',
            '\\dsum': '\\displaystyle\\sum_{#1}^#2',
            '\\tsum': '\\textstyle\\sum_{#1}^#2',
            '\\s': '\\ ',
            '\\w': '{#1}'
          }
        })
      };
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Box overflow="auto" id="scroll" flex={1} sx={{ maxHeight: '400px' }}>
      <Typography
        className="equation"
        variant="body1"
        dangerouslySetInnerHTML={createMarkup(props.text)}
      />
    </Box>
  );
};

export default KatexComponent;
