export const CLEAR_REGISTRATION_DATA = 'CLEAR_REGISTRATION_DATA';
export const GET_USER_AVAILABILITYPHONE = 'GET_USER_AVAILABILITYPHONE';
export const SET_USER_AVAILABILITYPHONE = 'SET_USER_AVAILABILITYPHONE';
export const GET_USER_AVAILABILITYEMAIL = 'GET_USER_AVAILABILITYEMAIL';
export const SET_USER_AVAILABILITYEMAIL = 'SET_USER_AVAILABILITYEMAIL';
export const GET_USER_CLIENT = 'GET_USER_CLIENT';
export const POST_USER_CLIENT = 'GET_USER_CLIENT';
export const POST_USER_LOGIN = 'POST_USER_LOGIN';
export const SET_USER_CLIENT = 'SET_USER_CLIENT';
export const POST_USER_REGISTER = 'POST_USER_REGISTER';
export const SET_USER_REGISTER = 'SET_USER_REGISTER';
export const GET_USER_OTP = 'GET_USER_OTP';
export const SET_USER_OTP = 'SET_USER_OTP';
export const POST_USER_REGISTERCOMPLETE = 'POST_USER_REGISTERCOMPLETE';
export const SET_USER_REGISTERCOMPLETE = 'SET_USER_REGISTERCOMPLETE';
export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const GET_USER_LOGOUT = 'GET_USER_LOGOUT';
export const POST_USER_CLEARREGISTRATION = 'POST_USER_CLEARREGISTRATION';
export const SET_USER_REFRESHTOKEN = 'SET_USER_REFRESHTOKEN';
export const POST_USER_FORGETPASSWORDINITIATE = 'POST_USER_FORGETPASSWORDINITIATE';
export const SET_USER_FORGETPASSWORDINITIATE = 'SET_USER_FORGETPASSWORDINITIATE';
export const POST_USER_FORGETPASSWORDCOMPLETE = 'POST_USER_FORGETPASSWORDCOMPLETE';
export const SET_USER_FPACTIVESTEP = 'SET_USER_FPACTIVESTEP';
export const GET_USER_FORGETPASSWORDOTP = 'GET_USER_FORGETPASSWORDOTP';
export const SET_USER_FORGETPASSWORDOTP = 'SET_USER_FORGETPASSWORDOTP';
export const POST_USER_TESTOTP = 'POST_USER_TESTOTP';
export const SET_USER_TESTOTP = 'SET_USER_TESTOTP';
export const GET_USER_RESENDOTP = 'GET_USER_RESENDOTP';
export const SET_USER_RESENDOTP = 'SET_USER_RESENDOTP';
